export const topicsArr = [
  "Admission/Application Essay",
  "Annotated Bibliography",
  "Argumentative Essay",
  "Article Review/Critique",
  "Assessment",
  "Book Report/Review",
  "Business Plan",
  "Capstone Project",
  "Case Study",
  "Coursework",
  "Cover Letter Writing",
  "Discussion Post",
  "Dissertation",
  "Dissertation Chapter - Abstract",
  "Dissertation Chapter - Discussion",
  "Dissertation Chapter - Introduction Chapter",
  "Dissertation Chapter - Literature Review",
  "Dissertation Chapter - Methodology",
  "Dissertation Chapter - Results",
  "Editing",
  "Essay",
  "Formatting",
  "Lab Report",
  "Math Problem",
  "Movie Review",
  "Multiple Choice Questions",
  "Non-word assignment",
  "Other",
  "Outline",
  "Paraphrasing",
  "Personal Statement",
  "Poster / Map",
  "PowerPoint Presentation Plain",
  "PowerPoint Presentation with Speaker Notes",
  "Proofreading",
  "Reaction paper",
  "Report",
  "Research Paper",
  "Research Proposal",
  "Resume Editing",
  "Resume Writing",
  "Retyping (PDF / PNG / Handwriting to Word)",
  "Rewriting",
  "Scholarship Essay",
  "Speech",
  "Statistics Project",
  "Summary",
  "Term Paper",
  "Thesis",
  "Topic Suggestion + Summary + References",
  "Thesis Proposal",
  "Topic Suggestion"
]