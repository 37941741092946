import React from 'react'
import './Pricing.css'

function Pricing() {
  return (
    <div className="pricing-section mt-lg-3" style={{backgroundColor:'#f0f6ff'}}>
                <div className="container" >
                    <div className="row justify-content-center align-items-center g-2" >
                        <div className="col-8">
                        <p className="m-0  text-center fw-bold pricing fs-1"> <span>Our Pricing Plan</span> </p>
                        </div>
                        
                            <div className="col-8 text-center fw-bold">
                            <p>Select the price that corresponds to the level of your question</p>
                        </div>
                        <div className="col-12 mb-5">
                            <section className='row justify-content-center align-items-center'>
                            <div className="col-lg-3 col-md-6 col-12 mb-3">
                                <div className="card text-center border-0 budget-card">
                                <div className="card-body" style={{backgroundColor:'pricing'===5?'#FFB704':'#fff'}}>
                                    <p className="card-text m-0">Academic Level</p>
                                    <h4 className="card-title text-primary">High School</h4>
                                    <ul className="text-start list-unstyled px-3">
                                        <li className="card-text d-flex justify-content-between"><p>20 days</p> <p><span className='fw-bold'>$10</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>14 days</p> <p><span className='fw-bold'>$11</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>9 days</p>  <p><span className='fw-bold'>$12</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>7 days</p>  <p><span className='fw-bold'>$13</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>5 days</p>  <p><span className='fw-bold'>$14</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>3 days</p>  <p><span className='fw-bold'>$15</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>2 days</p>  <p><span className='fw-bold'>$18</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>24 hours</p><p><span className='fw-bold'>$20</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>12 hours</p> <p><span className='fw-bold'>$22</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>6 hours</p>  <p><span className='fw-bold'>$25</span></p></li>
                                    </ul>
                                    
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 mb-3">
                                <div className="card text-center border-0 budget-card">
                                <div className="card-body" style={{backgroundColor:'pricing'===5?'#FFB704':'#fff'}}>
                                    <p className="card-text m-0">Academic Level</p>
                                    <h4 className="card-title text-primary">Undergraduate</h4>
                                    <ul className="text-start list-unstyled p-3">
                                        <li className="card-text d-flex justify-content-between"><p>20 days</p> <p><span className='fw-bold'>$11</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>14 days</p> <p><span className='fw-bold'>$12</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>9 days</p>  <p><span className='fw-bold'>$13</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>7 days</p>  <p><span className='fw-bold'>$14</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>5 days</p>  <p><span className='fw-bold'>$15</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>3 days</p>  <p><span className='fw-bold'>$17</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>2 days</p>  <p><span className='fw-bold'>$20</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>24 hours</p><p><span className='fw-bold'>$22</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>12 hours</p> <p><span className='fw-bold'>$24</span></p></li>
                                        <li className="card-text d-flex justify-content-between"><p>6 hours</p>  <p><span className='fw-bold'>$27</span></p></li>
                                    </ul>
                                    
                                </div>
                                </div>
                            </div>
                      <div className="col-lg-3 col-md-6 col-12 mb-3">
                        <div className="card text-center border-0 budget-card">   
                          <div className="card-body" style={{backgroundColor:'pricing'===10?'#FFB704':'#fff'}}>
                            <p className="card-text m-0">Academic Level</p>
                            <h4 className="card-title text-primary">Masters</h4>
                            <ul className="text-start list-unstyled p-3">
                                <li className="card-text d-flex justify-content-between"><p>20 days</p> <p><span className='fw-bold'>$12</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>14 days</p> <p><span className='fw-bold'>$13</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>9 days</p>  <p><span className='fw-bold'>$14</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>7 days</p>  <p><span className='fw-bold'>$15</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>5 days</p>  <p><span className='fw-bold'>$17</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>3 days</p>  <p><span className='fw-bold'>$19</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>2 days</p>  <p><span className='fw-bold'>$22</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>24 hours</p><p><span className='fw-bold'>$24</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>12 hours</p> <p><span className='fw-bold'>$26</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>6 hours</p>  <p><span className='fw-bold'>$29</span></p></li>
                            </ul>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-12  mb-3">
                        <div className="card text-center border-0 budget-card">
                          <div className="card-body" style={{backgroundColor:'pricing'===15?'#FFB704':'#fff'}}>
                            <p className="card-text m-0">Academic Level</p>
                            <h4 className="card-title text-primary">PHD</h4>
                            <ul className="text-start list-unstyled">
                                <li className="card-text d-flex justify-content-between"><p>20 days</p> <p><span className='fw-bold'>$13</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>14 days</p> <p><span className='fw-bold'>$14</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>9 days</p>  <p><span className='fw-bold'>$15</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>7 days</p>  <p><span className='fw-bold'>$16</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>5 days</p>  <p><span className='fw-bold'>$19</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>3 days</p>  <p><span className='fw-bold'>$21</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>2 days</p>  <p><span className='fw-bold'>$24</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>24 hours</p><p><span className='fw-bold'>$26</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>12 hours</p> <p><span className='fw-bold'>$28</span></p></li>
                                <li className="card-text d-flex justify-content-between"><p>6 hours</p>  <p><span className='fw-bold'>$31</span></p></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                            </section>
                        </div>
                        <div className="col-12 mb-5">
                          <div className="col-8">
                            <p className="fs-2 fw-bold  m-1" style={{color:'#002270'}}>Breakdown of the Pricing </p>
                          </div>
                          <p style={{color:'#40545f'}}>Pricing plans vary based these factors.</p>
                          <div class="card " style={{boxShadow:'rgba(0, 0, 0, 0.15) 0px 2px 8px'}}>
                            <div class="card-body">
                            <p className="fs-4 fw-bold" style={{color:'#002270'}}>Academic Levels:</p>
                             <p style={{color:'#40545f'}}>The pricing section is organized by academic levels, namely High School, Undergraduate, Masters, and PhD. Each academic level likely corresponds to different levels of complexity and expertise required for the assignments.</p>
                            </div>
                          </div>
                          
                          <div class="card mt-3" style={{boxShadow:'rgba(0, 0, 0, 0.15) 0px 2px 8px'}}>
                            <div class="card-body">
                            <p className="fs-4 fw-bold" style={{color:'#002270'}}>Urgency:</p>
                          <p style={{color:'#40545f'}}>Each academic level has different pricing tiers based on the urgency of the assignment. For example, prices vary depending on whether the customer needs the assignment completed within 20 days, 14 days, 9 days, and so forth, down to as urgent as 6 hours. 
                            Prices tend to increase as the urgency of the assignment increases due to the need for quicker completion and potential prioritization over other tasks.</p>
                            </div>
                          </div>
                          
                        </div>
                       
                        
                    </div>
                    
                </div>
                
            </div>
  )
}

export default Pricing