import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faMessage,
  faHouse,
  faGears,
  faFolderClosed,
  faBell,
  faPaperPlane,
  faClipboardQuestion,
  faLayerGroup,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

function MembersDashboard({ requests, handleDeleteQ }) {
  const navigate = useNavigate();

  const [openPreview, setOpenPreview] = useState(false);
  const [previewCard, setPreviewCard] = useState(false);
  const [preview, setPreview] = useState();
  const [doc, setDoc] = useState();

  function handleReadmode(d) {
    setOpenPreview(true);
    setPreview(d);
    setPreviewCard(true);
    setDoc(new DOMParser().parseFromString(d.details, "text/html"));
  }

  return (
    <div className="membersDashboard">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center g-2">
          <div className="col-12 w-75 mt-5" style={{ height: "40vh" }}>
            <div className="card text-start h-100 w-100">
              <div className="card-body">
                <div className="row justify-content-around align-items-center g-2 h-100 w-100">
                  <div
                    className="col-3 border rounded text-center h-100  py-3 pointer"
                    onClick={() => navigate("/question")}
                  >
                    <div className="mx-auto ">
                      <p className="card-title text-uppercase fw-bold">
                        Order your Content here
                      </p>
                      <p className="card-text">
                        Get help from Qualified Professionals
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-3 border rounded text-center h-100  py-3 pointer"
                    onClick={() => navigate("/resources")}
                  >
                    <div className="mx-auto ">
                      <p className="card-title text-uppercase fw-bold">
                        Study Materials
                      </p>
                      <p className="card-text">
                        Browse through our rich collections of Research and
                        Notes
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-3 border rounded text-center h-100  py-3 pointer"
                    onClick={() => navigate("/resources")}
                  >
                    <div className="mx-auto ">
                      <p className="card-title text-uppercase fw-bold">Books</p>
                      <p className="card-text">
                        Library of Popular Academic books{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="w-75 mx-auto">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Active
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Completed
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="messages-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#messages"
                    type="button"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="false"
                  >
                    My Books and Notes
                  </button>
                </li>
              </ul>

              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div
                    className="row "
                    style={{
                      height: "auto",
                      maxHeight: "100vh",
                      overflowY: "scroll",
                    }}
                  >
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Topic</th>
                            <th scope="col">Discipline</th>
                            <th scope="col">Time Remaining</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requests && requests.length > 0 ? (
                            requests.reverse().map((request, index) => {
                              const isoDateString = request.deliverytime;
                              const date = new Date(isoDateString);
                              const formattedDate = date.toLocaleString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              );
                              return (
                                <tr key={index}>
                                  <td scope="row">{request.topic}</td>
                                  <td>{request.subject}</td>
                                  <td>{formattedDate}</td>
                                  <td>{request.payment_status}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4">No requests found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  profile
                </div>
                <div
                  class="tab-pane"
                  id="messages"
                  role="tabpanel"
                  aria-labelledby="messages-tab"
                >
                  messages
                </div>
              </div>
            </div>

            {/* Preview */}
            {openPreview && (
              <div className="row justify-content-between align-items-start  p-2 bg-light m-2 border rounded">
                <div className="row justify-content-center align-items-start g-2">
                  <div className="col-lg-4 col-12  gap-2 mt-2">
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">
                        Academic Level:
                      </span>{" "}
                      {preview.academicLevel}
                    </p>
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">
                        Discipline:
                      </span>{" "}
                      {preview.subject}
                    </p>
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">Topic:</span>{" "}
                      {preview.topic}
                    </p>
                  </div>
                  <div className="col-lg-4 col-12  gap-2 mt-2">
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">
                        Budget $:
                      </span>{" "}
                      {preview.budget}
                    </p>
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">Pages:</span>{" "}
                      {preview.pageCount}
                    </p>
                    <p className=" m-0">
                      {" "}
                      <span className="text-muted fw-light">User:</span>{" "}
                      {preview.user.username}
                    </p>
                  </div>
                  <div className=" col-lg-4 col-12 d-flex justify-content-end align-items-start gap-2 mt-2">
                    <button
                      className="btn close"
                      onClick={() => {
                        setOpenPreview(false);
                        setPreview("");
                      }}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </button>
                  </div>
                  <hr />
                  {/* <div className="col">{preview.details}</div> */}
                  <div
                    className="col"
                    dangerouslySetInnerHTML={{ __html: preview.details }}
                  ></div>
                  <hr />
                  <p className=" m-0">
                    {" "}
                    <span className="text-muted fw-light">Attachments:</span>
                  </p>
                  <div className="row">
                    {preview.files &&
                      preview.files.map((file, index) => (
                        <div className="card col-4 overflow-hidden" key={index}>
                          <p className="m-0">
                            <span className="text-muted">File name: </span>
                            {file.name}
                          </p>
                          <p className="m-0">
                            <span className="text-muted">File type:</span>{" "}
                            {file.content_type}{" "}
                          </p>
                          <a href={file.url} download>
                            Download
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembersDashboard;
