import React, { useState,useContext } from 'react'
import {UrlContext} from '../../context/url'

function ResetPasswordEdit() {

  const  {apiUrl} = useContext(UrlContext)

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const searchParams = new URLSearchParams(window.location.search);

  const handleUpdatePassword = async (e) => {
    e.preventDefault()
    try {
      const token = searchParams.get('token')
      if (password !== passwordConfirmation) {
        alert("Passwords don't match");
        password("")
        passwordConfirmation("")
      } else {

        const response = await fetch(apiUrl + `/password_reset_edit/${token}`, {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
            Accepts: 'application/json'
          },
          body: JSON.stringify({
            password,
            password_confirmation: passwordConfirmation
          })
        });
        console.log(response); // Handle success, maybe redirect to login page
      }
    } catch (error) {
      console.error('Error updating password:', error);
      // Handle error, show an error message to the user
    }
  };

  return (
    <div>
      <h2>Reset your password</h2>
      <label>Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <label>Password Confirmation:</label>
      <input
        type="password"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
      />
      <button onClick={handleUpdatePassword}>Reset Password</button>
    </div>
  )
}

export default ResetPasswordEdit



