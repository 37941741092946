import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About/About";
import toast, { Toaster } from "react-hot-toast";
import Signup from "./components/Signup/Signup";
import Signin from "./components/Signin/Signin";
import React, { useEffect, useState, useContext } from "react";
import Resources from "./pages/Resources/Resources";
import Question from "./pages/Question/Question";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserProfile from "./pages/UserProfile/UserProfile";
import { Outlet, useLocation, useNavigate } from "react-router";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ResetPasswordEdit from "./components/ResetPasswordEdit/ResetPasswordEdit";
import Pricing from "./pages/Pricing/Pricing";
import Reviews from "./pages/Reviews/Reviews";
import { CableContext } from "./context/cable";
import { UrlContext } from "./context/url";
import ImageSlider from "./components/ImageSlider/ImageSlider";
import WriterDashboard from "./pages/WriterDashboard/WriterDashboard";
import WritersHome from "./components/Writers/WritersHome";
import MembersDashboard from "./pages/MembersDashboard/MembersDashboard";
import Payment from "./components/Payment/Payment";

function App() {
  const cable = useContext(CableContext);
  const { apiUrl } = useContext(UrlContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isSiginpOpen, setSigninOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [openNav, setOpenNav] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const [requests, setRequests] = useState([]);

  const [messages, setMessages] = useState([]);
  const [receiver_id, setReceiver_id] = useState();
  const [showMessages, setShowMessages] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [allReviews, setAllReviews] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [content, setContent] = useState("");
  const [channel, setChannel] = useState("");

  

  const location = useLocation();

  // !persist user logged
  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const user_id = localStorage.getItem("kqt_user_id");

    if (token && user_id) {
      const id = parseInt(user_id);

      fetch(apiUrl + `/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error(`Error: ${resp.status}`);
          }
        })
        .then((current_user) => {
          setUser(current_user);
          setIsLoggedIn(true);
          let home
          if (current_user.role === 'admin') {
            home = '/admin_home'
          } else if (current_user.role === 'writer') {
            home = '/writer_home'
          }else if (current_user.role === 'member') {
             home = '/member_home'
          }
          navigate(home)
        })
        .catch((err) => {
          console.error(err);
          setIsLoggedIn(false);
        });
    } else {
      setIsLoggedIn(false);
    }
  }, [setUser]);

  const toggleSignup = () => {
    setSignupOpen((prevIsSignupOpen) => !prevIsSignupOpen);
    setOpenNav(false);
  };

  const toggleSignin = () => {
    setSigninOpen((prevIsSigninOpen) => !prevIsSigninOpen);
    setOpenNav(false);
  };
  const toggleResetOpen = () => {
    setResetOpen((prevResetOpen) => !prevResetOpen);
    setOpenNav(false);
    setSigninOpen(false);
  };

  //!switch sidenav in sm mode
  function toggleNav() {
    setOpenNav((prevVal) => !prevVal);
  }

  // !fetches questions if User is true
  useEffect(() => {
    if (isLoggedIn && user.role === "admin") {
      fetch(apiUrl + "/questions")
        .then((resp) => resp.json())
        .then((data) => {
          if (data.length > 0) {
            setRequests(data.reverse());
          }
        });
    } else if (isLoggedIn && user.role !== "admin") {
      const user_id = user.id;
      fetch(apiUrl + `/questions/${user_id}`, {
        method: "GET",
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.length > 0) {
            setRequests(data.reverse());
          }
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    }
  }, [user, isLoggedIn]);

  // !users useEffect
  useEffect(() => {
    if (user && user.role === "admin") {
      fetch(apiUrl + "/users")
        .then((resp) => resp.json())
        .then((d) => {
          setAllUsers(d);
        });
    }
  }, [user, isLoggedIn]);

  // !moderator useEffect
  useEffect(() => {
    if (user && user.role !== "admin") {
      const token = localStorage.getItem("jwt");
      fetch(apiUrl + "/moderator", {
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => resp.json())
        .then((d) => {
          localStorage.setItem("moderator", d);
        });
    }
  }, [user, isLoggedIn]);

  // !Reviews
  useEffect(() => {
    fetch(apiUrl + "/reviews")
      .then((resp) => resp.json())
      .then((d) => {
        setAllReviews(() => d);
      });
  }, [user, isLoggedIn]);

  //! users useEffect
  useEffect(() => {
    if (user && user.role === "admin") {
      fetch(apiUrl + "/users")
        .then((resp) => resp.json())
        .then((d) => {
          setAllUsers(d);
        });
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    fetch(+"/reviews")
      .then((resp) => resp.json())
      .then((d) => {
        setAllReviews(d);
      });
  }, [user, isLoggedIn]);

  function openChat(arg) {
    setShowMessages(true);
    setMessages(() => messages);

    if (user.role !== "admin") {
      const mod = localStorage.getItem("moderator");
      setReceiver_id(() => mod);
    } else {
      setReceiver_id(() => arg.id);
    }
  }

  // !keeps questions channel always ON
  useEffect(() => {
    let subscription;

    if (!cable) {
      console.error("Action Cable connection not initialized");
      return;
    }

    if (user && user.role === "admin") {
      subscription = cable.subscriptions.create(
        {
          channel: "QuestionsChannel",
        },
        {
          received: (request) => {
            setRequests((prevRequests) => [...prevRequests, request]);
          },
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, user]);

  // !keeps chat channel always ON
  useEffect(() => {
    if (!cable) {
      console.error("Action Cable connection not initialized");
      return;
    }
    if (!channel) {
      const subscription = cable.subscriptions.create(
        {
          channel: "ChatChannel",
          user_id: user?.id,
          recipient_id: receiver_id,
        },
        {
          received: (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            notifyUser(message);
          },
        }
      );
      setChannel(subscription);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
        setChannel(null);
      }
    };
  }, [cable, channel, user, receiver_id]);

  // !keeps answers channel always ON
  useEffect(() => {
    if (!cable && !user) {
      console.error("Action Cable connection not initialized");
      return;
    }
    if (!channel) {
      const subscription = cable.subscriptions.create(
        {
          channel: "answersChannel",
          recipient_id: user?.id,
        },
        {
          received: (answers) => {
            console.log("ANS", answers);
            setAnswers((prevAnswers) => [...prevAnswers, answers]);
            notifyUser(answers);
          },
        }
      );
      setChannel(subscription);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
        setChannel(null);
      }
    };
  }, [cable, channel, user]);

  // !sends messages
  function sendMessage(e) {
    e.preventDefault();

    if (user.role !== "admin") {
      const mod = localStorage.getItem("moderator");
      setReceiver_id(() => mod);
    }
    if (channel) {
      const formData = new FormData();
      formData.append("user_id", user.id);
      formData.append("recipient_id", receiver_id);
      formData.append("chat_id", "");
      formData.append("content", content);
      formData.append("read_status", false);
      formData.append("delivery_status", false);
      fetch(apiUrl + "/messages", {
        method: "POST",
        body: formData,
      })
        .then((resp) => resp.json())
        .then((newMessage) => {
          setMessages(() => [...messages, newMessage]);
          fetchMessages(newMessage.chat_id);
          setContent("");
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          toast.error("error sending message");
        });
    }
  }

  const fetchMessages = async (chat_id) => {
    try {
      const resp = await fetch(apiUrl + `/messages/${chat_id}`);
      const messagesData = await resp.json();
      setMessages(messagesData);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // ! messages useEffect
  useEffect(() => {
    if (!user && isLoggedIn === false) {
      return;
    }
    if (user && user.role !== "admin" && user.chats.length > 0) {
      const chat_id = user.chats[0].id;
      fetch(apiUrl + `/messages/${chat_id}`)
        .then((resp) => resp.json())
        .then((d) => {
          setMessages(() => d);
        });
    } else if (user.role === "admin") {
      fetch(apiUrl + "/messages")
        .then((resp) => resp.json())
        .then((d) => {
          setMessages(d);
        });
    } else {
      setMessages("");
    }
  }, [user, isLoggedIn]);

  // ! answers useEffect
  useEffect(() => {
    if (!user && isLoggedIn === false) {
      return;
    }
    if (user.role === "admin") {
      return;
    }
    const user_id = user.id;
    fetch(apiUrl + `/answers/${user_id}`)
      .then((resp) => resp.json())
      .then((d) => {
        setAnswers(() => d);
      });
  }, [user, isLoggedIn]);

  // !connects to cable messages
  useEffect(() => {
    if (!user && isLoggedIn === false) {
      return;
    }
    if (user.role !== "admin") {
      const mod = localStorage.getItem("moderator");
      setReceiver_id(() => mod);
    }

    if (user.id) {
      const subscription = cable.subscriptions.create(
        {
          channel: "ChatChannel",
          user_id: user.id,
          recipient_id: receiver_id,
        },
        {
          received: (message) => {
            setMessages([...messages, message]);
            notifyUser(message);
          },
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  }, []);

  const notifyUser = (m) => {
    toast((t) => (
      <span>
        You have a new <b>Message</b>
        <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
      </span>
    ));
  };

  // !activities useEffect
  useEffect(() => {
    if (user && user.role !== "admin") {
      fetch(apiUrl + `/activity/${user.id}`)
        .then((resp) => resp.json())
        .then((d) => {
          setActivities(d.reverse());
        })
        .catch((err) => console.error(err));
    } else if (user && user.role === "admin") {
      fetch(apiUrl + "/activity")
        .then((resp) => resp.json())
        .then((d) => {
          setActivities(d.reverse());
        })
        .catch((err) => console.error(err));
    }
  }, [user, isLoggedIn, requests, messages]);

  // !delete question
  function handleDeleteQ(q_id) {
    const token = localStorage.getItem("jwt");
    fetch(apiUrl + `/questions/${q_id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          const newReq = requests.filter((req) => req.id !== q_id);
          setRequests(newReq);
          toast.success("Question deleted successfully.", {
            position: "bottom-center",
          });
        } else {
          console.error("Failed to delete question");
          toast("Question deleted successfully.", {
            position: "bottom-center",
            style: {
              borderRadius: "0",
              background: "#FFB704",
              color: "#fff",
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error occurred during the DELETE request", error);
      });
  }

  // !updates user account
  function handleAccountUpdate(e, formData) {
    e.preventDefault();
    const id = user.id;
    const promise = new Promise((resolve, reject) => {
      fetch(apiUrl + `/users/${id}`, {
        method: "PATCH",
        body: formData,
      })
        .then((resp) => resp.json())
        .then((data) => {
          setUser(() => data);
          resolve();
        })
        .catch(reject);
    });
    toast.promise(promise, {
      loading: "Updating...",
      success: "Updated Succesfully",
      error: "An error occurred",
    });
  }

  const PublicRoute = ({ user, children }) => {
    const user_id = localStorage.getItem("kqt_user_id");
    let home
   if (user && user_id) {
    if (user.role === 'admin') {
      home = '/admin_home'
    } else if (user.role === 'writer') {
      home = '/writer_home'
    }else if (user.role === 'member') {
       home = '/member_home'
    }
   }
    return  !user_id  ? children : navigate(home);
  };

  const AdminRoutes = () => {
    let admin = user?.role === "admin";
    return admin ? <Outlet /> : navigate("/")
  };
  const WriterRoutes = () => {
    let writer = user?.role === "writer";
    return writer ? <Outlet /> :  navigate("/")
  };

  const ClientRoutes = () => {
    let member = user?.role === "member";
    return member ? <Outlet /> :  navigate("/")
  };

  

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="App">
      <Toaster />
      {!location.pathname.includes("/userprofile") &&
        !location.pathname.includes("/dashboard") && (
          <Navbar
            openSignup={toggleSignup}
            openSignin={toggleSignin}
            user={user}
            setUser={setUser}
            setLogged={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
            toggleNav={toggleNav}
            openNav={openNav}
            loading={loading}
          />
        )}
      {isSignupOpen && (
        <Signup
          closeSignup={toggleSignup}
          onSignup={setIsLoggedIn}
          setUser={setUser}
          setLoading={setLoading}
        />
      )}
      {isSiginpOpen && (
        <Signin
          closeSignin={toggleSignin}
          setLogged={setIsLoggedIn}
          openReset={toggleResetOpen}
          setUser={setUser}
          setLoading={setLoading}
        />
      )}
      {resetOpen && <ResetPassword closeReset={toggleResetOpen} />}
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route
              path="/"
              element={
                <PublicRoute user={user}>
                  <Home openSignup={toggleSignup} loading={loading}>
                    <ImageSlider allReviews={allReviews} />
                  </Home>
                </PublicRoute>
              }
            />
             <Route
              path="/about"
              element={
                <About
                  openSignup={toggleSignup}
                  user={user}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route path="/resources" element={<Resources />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/reviews"
              element={
                <Reviews user={user} isLoggedIn={isLoggedIn}>
                  <ImageSlider allReviews={allReviews} />
                </Reviews>
              }
            />
          </>
        ) : (
          <>
            <Route
              path="/about"
              element={
                <About
                  openSignup={toggleSignup}
                  user={user}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route path="/resources" element={<Resources />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/reviews"
              element={
                <Reviews user={user} isLoggedIn={isLoggedIn}>
                  <ImageSlider allReviews={allReviews} />
                </Reviews>
              }
            />
            <Route
              path="/question"
              element={
                <Question
                  user={user}
                  setRequests={setRequests}
                  requests={requests}
                >
                </Question>
              }
            />
            <Route element={<AdminRoutes />}>
              <Route
                path="/admin_home"
                element={
                  <Dashboard
                    user={user}
                    allUsers={allUsers}
                    requests={requests}
                    setRequests={setRequests}
                    showMessages={showMessages}
                    sendMessage={sendMessage}
                    openChat={openChat}
                    messages={messages}
                    activities={activities}
                    setContent={setContent}
                    content={content}
                    allReviews={allReviews}
                    handleAccountUpdate={handleAccountUpdate}
                    setAllReviews={setAllReviews}
                  />
                }
              />
            </Route>
            <Route element={<WriterRoutes />}>
              <Route
                path="/writer_home"
                element={
                  <WriterDashboard
                    user={user}
                    allUsers={allUsers}
                    requests={requests}
                    setRequests={setRequests}
                    showMessages={showMessages}
                    sendMessage={sendMessage}
                    openChat={openChat}
                    messages={messages}
                    activities={activities}
                    setContent={setContent}
                    content={content}
                    allReviews={allReviews}
                    handleAccountUpdate={handleAccountUpdate}
                    setAllReviews={setAllReviews}
                  />
                }
              />
              <Route path="/writerDashboard/home" element={<WritersHome />} />
            </Route>
            <Route element={<ClientRoutes />}>
              <Route
                path="/member_home"
                element={
                  <MembersDashboard
                  
                     requests={requests}
                     handleDeleteQ={handleDeleteQ}
                   />
                  // <UserProfile
                  //   user={user}
                  //   messages={messages}
                  //   answers={answers}
                  //   requests={requests}
                  //   sendMessage={sendMessage}
                  //   toggleNav={toggleNav}
                  //   activities={activities}
                  //   handleDeleteQ={handleDeleteQ}
                  //   setContent={setContent}
                  //   content={content}
                  //   handleAccountUpdate={handleAccountUpdate}
                  // />
                }
              />
            </Route>
            <Route
              path="/password_reset_edit"
              element={<ResetPasswordEdit />}
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
