import React from 'react'

function WritersHome() {
  return (
    <div>
        <div class="card text-start">
            <div class="card-body">
                <h4 class="card-title">Title</h4>
                <p class="card-text">Body</p>
            </div>
        </div>
        
    </div>
  )
}

export default WritersHome