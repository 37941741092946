import React from 'react'


const UrlContext = React.createContext();


function UrlProvider({ children }) {

    // const apiUrl = "http://localhost:3000"
    const apiUrl = 'https://backend-knowledgequesttutors.onrender.com'
    // const frontendUrl = 'http://localhost:4000';
    const frontendUrl = 'https://frontend-knowedgequest.onrender.com';

    return (
        <UrlContext.Provider value={{ apiUrl, frontendUrl }}>
            {children}
        </UrlContext.Provider>
    );
}

export { UrlContext, UrlProvider }