import React from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import profile from '../../Assets/img/profile.jpg'

function WriterDashboard({ user }) {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center g-2">
                <div className="col-8 mx-auto">
                    <div className=" d-flex gap-3 justify-content-start align-items-center g-2 mb-3 " >
                        <div className="  d-flex justify-content-end align-items-center">
                            <div className="img_div" style={{ heigh: '5vh', width: '10vw' }}>
                                <img className="img-fluid rounded-circle" src={user.image ? user.image : profile} alt="info" />
                            </div>
                        </div>
                        <div className="profile-details ">
                            <div className="d-flex flex-column justify-content-start align-items-start gap-5  g-2">
                                <div
                                    className="d-flex justify-content-start align-items-center gap-2 g-2"
                                >
                                    <div className="text-capitalize">
                                        <p className="m-0">{user.username}</p>
                                    </div>
                                    <div className="text-capitalize"><p className="m-0">Ratings</p></div>
                                    <div className="text-capitalize">tasks completed</div>
                                    <div className="text-capitalize">Label</div>
                                </div>
                                <div
                                    className="row justify-content-start align-items-center g-2"
                                >
                                    <ul className="list-unstyled d-flex justify-content-center align-items-center gap-3">
                                        <li><p className="m-0">Success Rate</p></li>
                                        <li><p className="m-0">In Progress</p></li>
                                        <li><p className="m-0">Warning</p></li>
                                        <li><p className="m-0">Suspension</p></li>
                                        <li><p className="m-0">Overdue</p></li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div >
                        <ul className="list-unstyled d-flex justify-content-around align-items-center gap-3">
                            <NavLink to="/writerDashboard/home"><li><p className="fw-bold m-0">Home</p></li></NavLink>
                            <li><p className="fw-bold m-0">Statistics</p></li>
                            <li><p className="fw-bold m-0">Calender</p></li>
                            <li><p className="fw-bold m-0">Reports</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center g-2">
                <div className="col-8 mx-auto">
                    <Outlet />
                </div>
            </div>


        </div>
    )
}

export default WriterDashboard