import icon from '../../Assets/icons/icon.png'
import React, { useState, useEffect, useRef, useContext } from 'react'
import './Dashboard.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import profile from '../../Assets/img/profile.jpg'
import toast, { Toaster } from 'react-hot-toast';
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faMagnifyingGlass, faBell, faUser, faHouseChimney, faStarHalfStroke, faChartSimple, faBars, faGear, faInbox, faEllipsisVertical, faFile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { CableContext } from '../../context/cable';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import { UrlContext } from '../../context/url'


function Dashboard({ allUsers, requests, setRequests, sendMessage, openChat, messages, chats, showMessages, setContent, content, user, allReviews, handleAccountUpdate, setAllReviews }) {

    const navigate = useNavigate()
    const cable = useContext(CableContext)

    const { apiUrl } = useContext(UrlContext);

    const [currentSection, setCurrentSection] = useState('home')
    const [expandSidebar, setExpandSidebar] = useState(true)
    const [recipient, setRecipient] = useState('')
    const [preview, setPreview] = useState('')
    const [openPreview, setOpenPreview] = useState(false)

    const [openContacts, setOpenContacts] = useState(false)

    // form states
    const [username, setUsername] = useState('')
    const [school, setSchool] = useState('')
    const [course, setCourse] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [profileImg, setProfileImg] = useState('');
    const [error, setError] = useState([])

    const [answers, setAnswers] = useState('')

    const requestsCount = requests ? requests.length : 0;
    const userCount = allUsers ? allUsers.length : 0;
    const chatsCount = chats ? chats.length : 0;
    const reviewsCount = allReviews ? allReviews.length : 0;

    // !Quilss modulees
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
        ],
    };
    const { quill, quillRef } = useQuill({ modules });

    // !Quills useEffect
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                const content = quillRef.current?.firstChild?.innerHTML;
                setAnswers(content)
            });
        }
    }, [quill, quillRef]);


    function handleOpenPreview(request) {
        setPreview(request)
        setOpenPreview(true)
    }

    const handleSidebarNav = (link) => {
        setCurrentSection(link)
    }
    const toggleSidebar = () => {
        setExpandSidebar((prevVal) => !prevVal)
    }

    const handleOpenContacts = () => {
        setOpenContacts(prevVal => !prevVal)
    }


    useEffect(() => {
        if (user) {
            setUsername(user.username)
            setSchool(user.school)
            setCourse(user.course)
            setPhoneNumber(user.phoneNumber)
        }
    }, [user])

    const fileTypes = ["JPG", "PNG"];

    const handleChange = (profileImg) => {
        setProfileImg(profileImg);
    };

    //   updates acount
    function handleUserUpdate(e) {
        e.preventDefault()

        const formData = new FormData();

        formData.append('username', username)
        formData.append('school', school)
        formData.append('course', course)
        formData.append('phoneNumber', phoneNumber)
        if (profileImg instanceof File) {
            formData.append('img', profileImg);
        }

        handleAccountUpdate(e, formData)
    }

    const ref = useRef(null);
    useEffect(() => {
        if (messages.length) {
            ref.current?.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    }, [messages.length]);


    function handleReviewUpdate(e, id, status) {
        e.preventDefault()
        let approved
        if (status === 'approve') {
            approved = true
        } else if (status === 'reject') {
            approved = false
        }

        const token = localStorage.getItem("jwt");
        const promise = new Promise(async (resolve, reject) => {
            fetch(apiUrl + `/reviews/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accepts": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    approved: approved
                })
            })
                .then(resp => resp.json())
                .then(updatedReview => {
                    console.log(updatedReview)
                    setAllReviews(allReviews => {
                        const updatedReviews = allReviews.map(review => {
                            if (review.id === updatedReview.id) {
                                return updatedReview;
                            }
                            return review;
                        });
                        return updatedReviews;
                    });
                    resolve()
                })
                .catch(reject)
        })

        toast.promise(promise, {
            loading: "Fetching details...",
            success: "Updated",
            error: "An error occurred",
        })
    }

    function deleteReview(e, id) {
        e.preventDefault()

        const token = localStorage.getItem("jwt");
        const promise = new Promise((resolve, reject) => {
            fetch(apiUrl + `/reviews/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Accepts": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(resp => {
                    setAllReviews(allReviews => {
                        const updatedReviews = allReviews.filter(review => review.id !== id)
                        return updatedReviews;
                    })
                    resolve()
                })
                .catch(reject)
        })
        toast.promise(promise, {
            loading: "Fetching details...",
            success: "Deleted",
            error: "An error occurred",
        })
    }


    // !connects to cable
    useEffect(() => {
        if (!user) {
            return;
        }

        if (user && user.id) {
            const subscription = cable.subscriptions.create
                (
                    {
                        channel: 'QuestionsChannel',
                    },
                    {
                        received: (request) => {
                            setRequests([...requests, request])
                            notifyAdmin()
                        }
                    }
                )
            return () => {
                subscription.unsubscribe();
            };

        }
    }, [user])

    const notifyAdmin = (m) => {
        toast((t) => (
            <span>
                You have a new <b>Request</b>
                <button onClick={() => toast.dismiss(t.id)}>
                    Dismiss
                </button>
            </span>
        ));
    }
    // background color for cards based on order processing
    function getRequestBackgroundColor(orderStatus) {
        switch (orderStatus) {
            case 'pending':
                return 'bg-danger'; // Red background for pending status
            case 'processing':
                return 'bg-warning'; // Orange background for processing status
            case 'completed':
                return 'bg-success'; // blue background for complete status
            default:
                return ''; // Default background color
        }
    }

    const UpdateOrderStatus = async (id) => {
        console.log('OKRr');
        const token = localStorage.getItem("jwt");
        try {
            const response = await fetch(apiUrl + `/questions/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accepts": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    order_status: 'processing'
                })
            })
            const data = await response.json()
            console.log('D', data);
            if (response.ok) {
                // Update the status of the request with the given id
                setRequests(prevRequests =>
                    prevRequests.map(request =>
                        request.id === id ? { ...request, order_status: 'processing' } : request
                    )
                )
                console.error(data.errors)
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    }
    const handleSubmitResource = (e, q_id) => {
        setError('')
        if (!answers) {
            setError('content is empty')
            return
        }
        e.preventDefault()
        const token = localStorage.getItem("jwt");
        fetch(apiUrl + `/answers`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accepts": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                content: answers,
                question_id: q_id
            })
        })
            .then(resp => {
                if (!resp.ok) {
                    return resp.json().then(errorData => {
                        setError(errorData.message)
                        throw new Error(errorData.message || 'Something went wrong');
                    });
                }
                return resp.json();
            })
            .then(data => {
                console.log('Response data:', data);
                const updatedRequests = requests.map(request => {
                    if (request.id === data.question.id) {
                        return data;
                    } else {
                        return request;
                    }
                });
                // Set the updated state
                setRequests(() => updatedRequests);
            })
            .catch(error => {
                console.error('Error:', error.message);
                setError(error.message); // Set error state with the error message
            });
    }
    return (
        <div className="dashboard-container  wrapper " style={{ height: '100vh' }}>
            <Toaster />
            <div className=" d-flex p-0 m-0">
                <div className=" sidebar  d-flex bg-dark flex-column justify-content-between " style={{ width: expandSidebar ? '17vw' : '5vw', height: '100vh' }}>
                    <ul className="list-unstyled mx-auto pt-3 text-white" >
                        <li className='px-0 py-2 d-flex justify-content-center gap-3 lh-1' onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                            <div className="img-div" style={{ height: '20px', width: '20px' }}>
                                <img className='img-fluid ' src={icon} alt="info" />
                            </div>
                            {expandSidebar && <p className="m-0 fw-bold">KnowledgeQ</p>}
                        </li>
                        <li className='py-3 px-3 d-flex justify-content-start gap-3 lh-1 ' onClick={() => handleSidebarNav('home')}>
                            <FontAwesomeIcon icon={faHouseChimney} />
                            {expandSidebar && <p className="m-0">Dashboard</p>}
                        </li>
                        <li className='py-3 px-3 d-flex justify-content-start gap-3 lh-1 ' onClick={() => handleSidebarNav('requests')}>
                            <FontAwesomeIcon icon={faFile} />
                            {expandSidebar && <p className="m-0">Requests</p>}
                        </li>
                        {/* } */}
                        <li className='py-3 px-3 d-flex justify-content-start gap-3 lh-1 ' onClick={() => handleSidebarNav('messages')}>
                            <FontAwesomeIcon icon={faInbox} />
                            {expandSidebar && <p className="m-0">Messages</p>}
                        </li>
                        <li className='py-3 px-3 d-flex justify-content-start gap-3 lh-1 ' onClick={() => handleSidebarNav('reviews')}>
                            <FontAwesomeIcon icon={faStarHalfStroke} />
                            {expandSidebar && <p className="m-0">Reviews</p>}
                        </li>
                        <li className='py-3 px-3 d-flex justify-content-start gap-3 lh-1 ' onClick={() => handleSidebarNav('stats')}>
                            <FontAwesomeIcon icon={faChartSimple} />
                            {expandSidebar && <p className="m-0">Stats</p>}
                        </li>
                    </ul>
                    <div className='d-flex justify-content-center mb-3'>
                        <button className='btn py-2 px-3 d-flex justify-content-start gap-3 lh-1 text-white' onClick={() => handleSidebarNav('settings')}>
                            <FontAwesomeIcon icon={faGear} />
                            {expandSidebar && <p className="m-0">Settings</p>}
                        </button>
                    </div>
                </div>

                {/* main section */}
                <div className=" main_section m-0 bg-light " style={{ width: expandSidebar ? '82vw' : '94vw', height: '100vh', overflowY: 'scroll' }}>
                    <div className="border bg-white rounded p-2 m-2 d-flex  align-items-end justify-content-between">
                        <div><button className="btn" onClick={() => toggleSidebar()}><FontAwesomeIcon icon={faBars} /></button></div>
                        <div>
                            <ul style={{ listStyle: 'none' }}>
                                <li className="nav-item px-lg-5">
                                    <NavLink to='/' className="nav-link" aria-current="page" ><FontAwesomeIcon icon={faHouseChimney} /> Home</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {currentSection === 'home' &&
                        <>
                            <section>
                                <div className="row justify-content-end align-items-center g-2">
                                    <div className="col-3 ">
                                        <ul className='d-flex list-unstyled gap-4 justify-content-end   my-0 me-4 align-items-center'>
                                            <li><FontAwesomeIcon icon={faMagnifyingGlass} /></li>
                                            <li><FontAwesomeIcon icon={faBell} /></li>
                                            <li><FontAwesomeIcon icon={faUser} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <div className="row justify-content-center align-items-start g-2 ps-3"
                            >
                                <div className="col-lg-4 col-12 card " onClick={() => handleSidebarNav('requests')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', height: '80vh', overflowY: 'scroll' }}>
                                    <p className='fw-bold p-2 m-0'>Requests</p>
                                    {requests && requests.map((request) => {
                                        return (
                                            <div className="card mx-1 my-2">
                                                <div className="card-body p-1">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="card-text fw-bold ">{request.subject}</p>
                                                        <p className="card-text"> <small>Delivery Time:</small> {request.deliverytime}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="card-text">{request.title}</p>
                                                        <p className="card-text fw-light ">{request.academicLevel}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col-lg-8 col-12 d-flex flex-wrap">
                                    <div
                                        className="row col-12 p-2  justify-content-start align-items-center g-2" >
                                        <div className="col-md-3 col-6 ">
                                            <div className="card border-0 text-start text-white" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: '#65b1fc' }}>
                                                <div className="card-body p-1 px-2">
                                                    <h4 className="card-title">{userCount}</h4>
                                                    <p className="card-text">Current Users</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="card border-0 text-start text-white" onClick={() => handleSidebarNav('messages')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: '#fe8205' }}>
                                                <div className="card-body p-1 px-2">
                                                    <h4 className="card-title">{chatsCount}</h4>
                                                    <p className="card-text">Messages</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="card text-start bg-light" onClick={() => handleSidebarNav('requests')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                                <div className="card-body p-1 px-2">
                                                    <h4 className="card-title">{requestsCount}</h4>
                                                    <p className="card-text">Requests</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <div className="card text-start text-white border-0" onClick={() => handleSidebarNav('reviews')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: '#47cf73' }}>
                                                <div className="card-body p-1 px-2">
                                                    <h4 className="card-title">{reviewsCount}</h4>
                                                    <p className="card-text">Reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-12 m-2 border rounded bg-light" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                        <p className='fw-bold p-2 m-0'>Users</p>
                                        <div >
                                            {allUsers && allUsers.map((user) => {
                                                return (
                                                    <div key={user.id} className="card  m-2 ">
                                                        <div className="d-flex g-0 justify-content-start gap-2 align-items-start px-2 py-1">
                                                            <div className='div-img border ' style={{ borderRadius: '50%', height: '50px', width: '50px' }}>
                                                                <img
                                                                    src={user.img}
                                                                    className="img-fluid "
                                                                    alt="info"
                                                                    style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '50%' }}
                                                                />
                                                            </div>
                                                            <div className="card-content overflow-hidden">
                                                                <p className="card-text m-0 text-capitalize" style={{ fontWeight: '500' }}>{user.username}</p>
                                                                <p className="card-text">{user.school}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 m-1">
                                        <div className="border  py-1 align-items-start">
                                            <p className=" my-0 test-start fw-bold">Your currest threads</p>
                                            <div className="card text-start p-2 d-flex justify-content-center">
                                                <div className="card-details border row m-0">
                                                    <div className="card-img-div border bg-light p-1 col-3" style={{ height: '40px', width: '40px' }}>
                                                        {/* <img className="card-img img-fluid " src={profile} alt="Title" /> */}
                                                    </div>
                                                    <div className="col-8">
                                                        <p className="card-text">message body</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </>
                    }
                    {currentSection === 'requests' && !openPreview &&
                        <>
                            <div className="row m-0 p-0">
                                {requests && requests.map((request, index) => {
                                    const isoDateString = request.deliverytime;
                                    const date = new Date(isoDateString);
                                    const formattedDate = date.toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    })
                                    return (
                                        <div className="col-lg-12 ">
                                            <div key={index} className="card text-start bg-light p-2 m-2 overflow-hidden" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                                <div className={`card-header border-0 text-white ${getRequestBackgroundColor(request.order_status)}`}>
                                                    <p className='m-0'><span className='fw-bold'>Topic : </span>{request.topic}</p>
                                                </div>
                                                <div className="card-body p-1" onClick={() => handleOpenPreview(request)}>
                                                    <div className="row justify-content-between ">
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Academic Level:</p>
                                                            <p className="card-text  ">{request.academicLevel}</p>
                                                        </div>
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Discipline:</p>
                                                            <p className="card-text overflow-hidden">{request.subject}</p>
                                                        </div>
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Pages:</p>
                                                            <p className="card-text overflow-hidden">{request.pageCount}</p>
                                                        </div>
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Deadline:</p>
                                                            <p>{formattedDate}</p>
                                                        </div>
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Payment Status:</p>
                                                            <p>{request.payment_status}</p>
                                                        </div>
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 mt-2'>
                                                            <p className='text-muted fw-light m-0'>Client:</p>
                                                            <p>{request.user?.username}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between ">
                                                        <div className='col-lg-4 col-12 d-flex align-items-start gap-2 '>
                                                            <p className='text-muted fw-light m-0 '>Order Status:</p>
                                                            <p className='text-capitalize text-danger'>{request.order_status}</p>
                                                        </div>
                                                        {request.order_status !== 'completed' &&
                                                            <div className='col-lg-8 col-12 d-flex align-items-start gap-2 '>
                                                                <p className='text-muted fw-light m-0 text-danger'>Time Left:</p>
                                                                <CountdownTimer targetDate={request.deliverytime} />
                                                            </div>}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {requests.length === 0 &&
                                    <>
                                        <div className="col-6 mx-auto text-center">
                                            <p className='fw-light'>No requests sent</p>
                                        </div>
                                    </>
                                }

                            </div>
                        </>
                    }
                    {openPreview &&
                        <div className="card text-start bg-light p-2 m-2 red" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                            <div
                                className="row justify-content-center align-items-center g-2"
                            >

                                <div className="row">
                                    <div className='col-lg-4 col-12  gap-2 mt-2'>
                                        <p className=' m-0'> <span className='text-muted fw-light'>Academic Level:</span>   {preview.academicLevel}</p>
                                        <p className=' m-0'> <span className='text-muted fw-light'>Discipline:</span>   {preview.subject}</p>
                                        <p className=' m-0'> <span className='text-muted fw-light'>Topic:</span>   {preview.topic}</p>
                                    </div>
                                    <div className='col-lg-4 col-12  gap-2 mt-2'>
                                        <p className=' m-0'> <span className='text-muted fw-light'>Budget $:</span>   {preview.budget}</p>
                                        <p className=' m-0'> <span className='text-muted fw-light'>Pages:</span>   {preview.pageCount}</p>
                                        <p className=' m-0'> <span className='text-muted fw-light'>User:</span>   {preview.user.username}</p>
                                    </div>
                                    <div className='col-lg-4 col-12 d-flex justify-content-end align-items-start gap-2 mt-2'>
                                        <button className="btn close" onClick={() => { setOpenPreview(false); setPreview('') }}><FontAwesomeIcon icon={faX} />
                                        </button>
                                    </div>
                                    <hr />
                                </div>
                                {/* <div className="col">{preview.details}</div> */}
                                <div className="col" dangerouslySetInnerHTML={{ __html: preview.details }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}></div>

                                {preview.files?.length > 0 &&
                                    <div >
                                        <hr />
                                        <p className=' m-0'> <span className='text-muted fw-light'>Attachments:</span></p>
                                        <div className="d-flex justify-content-around align-items-start gap-3" >
                                            {preview.files && preview.files.map((file, index) => (
                                                <div className="card  overflow-hidden p-1 w-25" key={index}>
                                                    <p className='m-0'><span className='text-muted'>File name: </span>{file.name}</p>
                                                    <p className='m-0'><span className='text-muted'>File type:</span>  {file.content_type} </p>
                                                    <a href={file.url} download>Download</a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {preview.order_status === "pending" &&
                                    <div
                                        className="row justify-content-between align-items-center g-2">
                                        <div className="col-4">
                                            <button className="btn btn-info text-white" onClick={() => UpdateOrderStatus(preview.id)}>Process Order</button>
                                        </div>
                                    </div>
                                }
                                {preview.order_status === "review" &&
                                    <div
                                        className="row justify-content-between align-items-center g-2">
                                        <div className="col-4">
                                            <button className="btn btn-info text-white" onClick={() => UpdateOrderStatus(preview.id)}>Process Order</button>
                                        </div>
                                    </div>
                                }
                                {preview.order_status === "processing" &&
                                    <div className="row justify-content-between align-items-center g-2">
                                        <div className="col-8 col-md-12" style={{ maxHeight: '50vh', height: '50vh', width: '100%' }}>
                                            <div style={{ height: '40vh', width: '100%', background: 'white', color: 'black' }}>
                                                <div ref={quillRef} />
                                            </div>
                                        </div>
                                        {error &&
                                            <p className="text-danger">{error}</p>
                                        }
                                        <div className="col-4">
                                            <button className="btn btn-info text-white" onClick={(e) => handleSubmitResource(e, preview.id)}>Submit Order</button>
                                        </div>
                                    </div>
                                }
                                {preview.order_status === "completed" &&
                                    <div>
                                        {preview.answers.map((answer, index) => (
                                            <div className="col" key={index} dangerouslySetInnerHTML={{ __html: answer.content }}></div>
                                        ))}
                                    </div>
                                }
                            </div>

                        </div>
                    }
                    {currentSection === 'messages' &&
                        <>
                            <div
                                className="row justify-content-center align-items-start g-2 m-0"
                            >
                                <div className="col-12">
                                    <div className="head-section">
                                        <div
                                            className="row border justify-content-center align-items-center g-2"
                                        >
                                            <div className="col-3 d-flex justify-content-between align-items-center " style={{ borderRight: '1px solid black' }}>
                                                <p className='fs-4 fw-bold m-0 ps-3'>Going Chats</p>
                                                <button className='btn' onClick={() => handleOpenContacts()}><FontAwesomeIcon icon={faEllipsisVertical} /></button>

                                            </div>
                                            <div className="col-6  px-3">
                                                <input className='w-100  form-control' type="text" placeholder='Search' onChange={(e) => 'setFilteredChats'(e.target.value)} />
                                            </div>
                                            <div className="col-3 justify-content-end align-items-center d-flex  p-1 pe-2">
                                                <button className='m-0 p-2 bg-dark text-white' style={{ borderRadius: '50%' }}>US</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-3 p-1">
                                    {allUsers.filter(user => user.chats.length !== 0 && user.isAdmin === false).map((user) => {
                                        return (
                                            <div className="card my-2 " onClick={() => openChat(user)}>
                                                <div className="row g-0 justify-content-around align-items-start  ">
                                                    <div className='div-img border  col-md-2 ' style={{ borderRadius: '50%', height: '50px', width: '50px' }}>
                                                        <img
                                                            src={user.img}
                                                            className="img-fluid "
                                                            alt="info"
                                                            style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                                                        />
                                                    </div>
                                                    <div className="card-content col-md-7 ">
                                                        <p className="card-text fw-bold m-0 text-capitalize"> {user.username}</p>
                                                    </div>
                                                    <div className="card-details col-md-2">
                                                        <p className="card-text fw-light"> <small className="text-muted">3 mins</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {openContacts && allUsers &&

                                        allUsers.filter(user => user.isAdmin === false).map((user) => {
                                            return (
                                                <div className="card  my-2" onClick={() => openChat(user)}>
                                                    <div className="row g-0 justify-content-around align-items-start  ">
                                                        <div className='div-img border  col-md-2 ' style={{ borderRadius: '50%', height: '50px', width: '50px' }}>
                                                            <img
                                                                src={user.img}
                                                                className="img-fluid "
                                                                alt="info"
                                                                style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                                                            />
                                                        </div>
                                                        <div className="card-content col-md-7 ">
                                                            <p className="card-text fw-bold m-0 text-capitalize">{user.username}</p>

                                                        </div>
                                                        <div className="card-details col-md-2">
                                                            <p className="card-text fw-light"> <small className="text-muted">3 mins</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className="col-6 border">
                                    <div className="row m-0">
                                        <div className="d-flex flex-column justify-content-start g-2 dash_messager" style={{ height: '70vh', overflowY: 'auto' }}>
                                            {showMessages && messages && messages.map((m) => {
                                                return (
                                                    <div key={m.id} className="card border-0 bg-light my-2" onClick={console.log(m)}>
                                                        {m.user_2_id !== 1 ?
                                                            <div className="d-flex g-0 justify-content-between align-items-end " >
                                                                <button className='btn' ><FontAwesomeIcon icon={faEllipsisVertical} /></button>
                                                                <div className="d-flex g-0 justify-content-between gap-1 align-items-start">
                                                                    <div className="card-content ">
                                                                        <p className="card-text pt-1 chat_bubble from-me">
                                                                            {m.content}
                                                                        </p>
                                                                    </div>
                                                                    <div className='div-img border' style={{ borderRadius: '50%', height: '50px', minWidth: '50px' }}>
                                                                        <img
                                                                            // src={m.user.img !== '' ? m.user.img : profile}
                                                                            src={profile}
                                                                            className="img-fluid "
                                                                            alt="info"
                                                                            style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="d-flex g-0 justify-content-between align-items-end  ">
                                                                <div className="d-flex g-0 justify-content-start gap-1 align-items-start">
                                                                    <div className='div-img border' style={{ borderRadius: '50%', height: '50px', minWidth: '50px' }}>
                                                                        <img
                                                                            // src={m.user.img  !== '' ? m.user.img : profile}
                                                                            src={profile}
                                                                            className="img-fluid "
                                                                            alt="info"
                                                                            style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                                                                        />
                                                                    </div>
                                                                    <div className="card-content">
                                                                        <p className="card-text pt-1 chat_bubble from-them">
                                                                            {m.content}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <button className='btn'><FontAwesomeIcon icon={faEllipsisVertical} /></button>
                                                            </div>
                                                        }
                                                        <div ref={ref} />
                                                    </div>

                                                )
                                            })
                                            }
                                        </div>
                                        <div className="col-12">
                                            <form action="" onSubmit={(e) => { sendMessage(e) }}>
                                                <input type="text" value={content} onChange={(e) => { setContent(e.target.value) }} />
                                                <button type='submit' className="btn"><FontAwesomeIcon icon={faPaperPlane} /></button>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                                {recipient && <div className="col-3 m-0">
                                    <p className="card-text fw-bold text-capitalize">{recipient.username}</p>
                                    <div className='div-img' style={{ height: '200px', width: '200px' }}>
                                        <img
                                            src={recipient.img}
                                            className="img-fluid rounded"
                                            alt="info"
                                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text"> {recipient.email}</p>
                                        <p className="card-text"> {recipient.course ? recipient.course : ''}</p>
                                        <p className="card-text"> {recipient.school}</p>
                                        <p className="card-text"> {recipient.phoneNumber ? recipient.phoneNumber : ''}</p>
                                    </div>
                                </div>}
                                {!recipient && <div className="col-3 m-0">
                                    <p className="card-text fw-bold text-capitalize">{user.username}</p>
                                    <div className='div-img' style={{ height: '200px', width: '200px' }}>
                                        <img
                                            src={user.img}
                                            className="img-fluid rounded"
                                            alt="info"
                                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text"> {user.email}</p>
                                        <p className="card-text"> {user.course}</p>
                                        <p className="card-text"> {user.school}</p>
                                        <p className="card-text"> {user.phoneNumber}</p>
                                    </div>
                                </div>}

                            </div>


                        </>
                    }
                    {currentSection === 'reviews' &&
                        <div className="row m-0 p-0">
                            {allReviews && allReviews.reverse().map((review, index) => {
                                return (
                                    <div className="col-lg-6" onClick={() => console.log(review)} >
                                        <div key={index} className="card text-start p-2 m-2 overflow-hidden" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', backgroundColor: review.approved !== true ? 'orange' : '' }}>
                                            <div className="card-header border-0">
                                                <p className='m-0'><span className='fw-bold'>Username : </span>{review.user.username}</p>
                                            </div>
                                            <div className="card-body p-1 ">
                                                <div className="row justify-content-between ">
                                                    <div className='col-12 d-flex align-items-start gap-2 mt-2'>
                                                        <p className='text-muted fw-light m-0'>Rating:</p>
                                                        <p className="card-text overflow-hidden">{review.rating}</p>
                                                    </div>
                                                    <div className='col-12 d-flex align-items-start gap-2 mt-2'>
                                                        <p className='text-muted fw-light m-0'>Review:</p>
                                                        <p className="card-text  ">{review.review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer  d-flex justify-content-start gap-3">
                                                <div className="col-4"><button disabled={review.approved} className="btn btn-info" onClick={(e) => handleReviewUpdate(e, review.id, 'approve')}>Approve</button></div>
                                                <div className="col-4"><button disabled={!review.approved} className="btn btn-warning" onClick={(e) => handleReviewUpdate(e, review.id, 'reject')}>reject</button></div>
                                                <div className="col-4"><button className="btn btn-link" onClick={(e) => deleteReview(e, review.id)}>Delete</button></div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {requests.length === 0 &&
                                <>
                                    <div className="col-6 mx-auto text-center">
                                        <p>No requests sent</p>
                                    </div>
                                </>
                            }


                        </div>
                    }
                    {currentSection === 'stats' &&
                        <>
                            <section>
                                <div
                                    className="row justify-content-center align-items-center g-2"
                                >
                                    <div className="col-lg-8">
                                        <nav className='border'>
                                            <ul className='list-unstyled d-flex justify-content-around align-items-center  my-2'>
                                                <li>Overview</li>
                                                <li>Payments</li>
                                                <li>Cards</li>
                                                <li>Accounts</li>
                                                <li>System</li>
                                                <li>Business</li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="col-lg-4">

                                        <div className='user_details d-flex justify-content-start  align-items-center gap-2'>
                                            <div className='div-img border' style={{ borderRadius: '50%', height: '40px', width: '40px' }}>
                                                <img
                                                    src={user.img}
                                                    className="img-fluid "
                                                    alt="info"
                                                    style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                                                />

                                            </div>
                                            <div className='d-flex  flex-fill justify-content-between  align-items-center'>
                                                <p className="m-0">James Doe</p>
                                                <ul className='d-flex list-unstyled justify-content-end gap-3 my-0 me-4 align-items-center'>

                                                    <li><FontAwesomeIcon icon={faMagnifyingGlass} /></li>
                                                    <li><FontAwesomeIcon icon={faBell} /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </>
                    }
                    {currentSection === 'settings' &&
                        <>
                            <div className=" border mx-auto rounded col-11 px-3">
                                <p className='fw-bold fs-5'>Update profile</p>
                                <form >
                                    <div className="mb-3 row">
                                        <label className="col-4 col-form-label" >Username</label>
                                        <div className="col-8" >
                                            <input type="text" className="form-control" name="inputName" placeholder="Name" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-4 col-form-label" >School</label>
                                        <div className="col-8" >
                                            <input type="text" className="form-control" name="inputName" placeholder="Name" value={school} onChange={(e) => setSchool(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-4 col-form-label" >Course</label>
                                        <div className="col-8" >
                                            <input type="text" className="form-control" name="inputName" placeholder="Name" value={course} onChange={(e) => setCourse(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-4 col-form-label" >Phone Number</label>
                                        <div className="col-8" >
                                            <input type="number" className="form-control" name="inputName" placeholder="Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-4 col-form-label" >Profile image</label>
                                        <div className="col-8" >
                                            <FileUploader handleChange={handleChange} name="profileImg" types={fileTypes} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="offset-sm-4 col-sm-8">
                                            <button type="submit" className="btn border-primary" onClick={(e) => handleUserUpdate(e)}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    }
                </div>

            </div>

        </div>
    )
}

export default Dashboard
