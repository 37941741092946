import React, { useState, useContext } from 'react'
import ourStory from '../../Assets/img/ourStory.png'
import { motion } from 'framer-motion'
import ribbon from '../../Assets/icons/ribbon.png'
import congrats from '../../Assets/icons/congratulation.png'
import literacy from '../../Assets/icons/literacy.png'
import rank from '../../Assets/icons/rank.png'
import faqs from '../../Assets/img/faqs.png'
import { ArrowRight } from 'react-bootstrap-icons';
import students from '../../Assets/img/students.jpg'
import why2 from '../../Assets/img/why-2.png'
import why3 from '../../Assets/img/why-3.png'
import why4 from '../../Assets/img/why-4.png'
import why5 from '../../Assets/img/why-5.png'
import target from '../../Assets/icons/target.png'
import about from '../../Assets/img/about-us.png'
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './About.css'
import {UrlContext} from '../../context/url'

function About({ openSignup, user, isLoggedIn }) {

    const [currentCard, setcurrentCard] = useState('card1')
    const navigate = useNavigate()
    const  {apiUrl} = useContext(UrlContext)

    const [content, setContent] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')

    function handleCollapse(card) {
        setcurrentCard(card)
    }

    function handleComment(e) {
        if (!user && isLoggedIn === false) {
            return
        }
        console.log('commenting');
        e.preventDefault()
        fetch(apiUrl + '/comments', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accepts": "application/json"
            },
            body: JSON.stringify({
                user_id: user.id,
                content,
                email,
                username
            })
        })
            .then(resp => resp.json())
            .then(d => {
                console.log(d)

                setContent('')
                setEmail('')
                setUsername('')

            })
    }
    return (
        <div className='wrapper about-section border '>
            <div className="container ">
                <div className="row justify-content-around align-items-center g-2 gap-xl-5"   >
                    {/* <div className="col-12 text-center">
             <p className="faq  fw-bold"
                        style={{color:'#FFB704',fontSize:'30px'}}
                         ><span >Our Story</span></p>
                <motion.p className='about_mission'
                            style={{fontSize:'42px',lineHeight:'1.2',color:'#15388f'}}
                             initial={{opacity:0,y:100}}
                             animate={{opacity:1,y:0}}
                             transition={{duration:0.83}}
                             viewport={{once:true}}
                             >
                            Your Premier Destination for Educational Excellence</motion.p>
                    </div> */}
                    <div className="col-12 col-md-5 rounded py-5" style={{ position: 'relative' }}>
                        <img className='img-fluid  ' src={about} alt="info" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px' }} />

                        <div className="card px-2 border-0" style={{ position: 'absolute', bottom: '-30px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px' }}>
                            <div className="card-body text-center d-flex gap-3">
                                <img className="card-img" src={ribbon} alt="Title" style={{ height: '60px', width: '60px' }} />
                                <div className='card-details text-start'>
                                    <p className="card-text fs-2 fw-bold m-0">1+</p>
                                    <p className="card-title m-0 ">Years of Excellence</p>
                                </div>
                            </div>
                        </div>
                        <div className="card d-none d-xl-flex border-0" style={{ position: 'absolute', right: '-30%', top: '65%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px' }}>
                            <div className="card-body text-center d-flex p-1" style={{ height: '300px', width: '300px' }}>
                                <img className="card-img" src={students} alt="Title" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5  text-start ps-xl-5 pt-5 pt-md-0">
                        <p className="col-8 about fs-3 fw-bold"
                        ><span >About Us</span></p>
                        <motion.p className='about_mission'
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.83 }}
                            viewport={{ once: true }}
                        >Our Mission is to empower learners by providing personalized, accessible, and effective online tutoring</motion.p>
                        <motion.p className='text-muted' style={{ fontSize: '14px' }}
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                        >We are driven by the belief that education has the power to shape a brighter future, and we are committed to making a positive impact on the lives of our students, helping them achieve their full potential.</motion.p>
                        <motion.button className="btn border-black rounded-0"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                            onClick={() => navigate('/about#faqs')}
                        >FAQs <ArrowRight /></motion.button>
                    </div>
                    <div className="col-12 col-md-6  text-start ps-xl-5 pt-5 pt-md-0">
                        <motion.p className="col-10 about fs-4 fw-bold"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.93 }}
                            viewport={{ once: true }}
                        ><span >Our vision</span></motion.p>
                        <motion.p className='about_mission'
                            style={{ fontSize: '42px', lineHeight: '1.2' }}
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.83 }}
                            viewport={{ once: true }}
                        >Elevate Your
                            <span style={{ color: '#FFB704' }}> Career </span>
                            Learning Experience.</motion.p>
                        <motion.p style={{ fontSize: '14px' }}
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                            className='m-0'
                        >We are driven by the belief that education has the power to shape a brighter future, and we are committed to making a positive impact on the lives of our students, helping them achieve their full potential.</motion.p>
                        <ul className="list-unstyled big-dots">
                            <li className='lh-1 text-muted'>The foundation for a lifetime of success</li>
                            <li className='lh-1 text-muted'>Find the right instructor for you</li>
                            <li className='lh-1 text-muted'>Popular topics to learn now</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-5 rounded py-5" style={{ position: 'relative' }}>
                        <img className='img-fluid  ' src={ourStory} alt="info" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px' }} />

                        <div className="card px-2 border-0" style={{ position: 'absolute', bottom: '20vh', left: '-5vw', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px' }}>
                            <div className="card-body text-center d-flex gap-3">
                                <img className="card-img" src={ribbon} alt="Title" style={{ height: '60px', width: '60px' }} />
                                <div className='card-details text-start'>
                                    <p className="card-title m-0 ">Student Story</p>
                                    <p className="card-text fs-2 fw-bold m-0">97%</p>
                                    <p className="card-title m-0 ">Student Succes History</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section stats-section pb-5 d-flex justify-content-center align-items-center g-2" >
                <div className="container rounded-4" style={{ backgroundColor: '#010e31', minHeight: '40vh' }}>
                    <div
                        className="row justify-content-center align-items-center g-2 my-5"
                    >
                        <motion.div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                        >
                            <div className="card bg-transparent text-center text-white border-0">
                                <div className="card-body ">
                                    <img className="card-img" src={rank} alt="Title" style={{ height: '70px', width: '70px' }} />
                                    <h4 className="card-title fw-bold fs-1">10+</h4>
                                    <p className="card-text">Courses Covered</p>
                                </div>
                            </div>

                        </motion.div>
                        <motion.div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                        >
                            <div className="card bg-transparent text-center text-white border-0">
                                <div className="card-body ">
                                    <img className="card-img" src={literacy} alt="Title" style={{ height: '70px', width: '70px' }} />
                                    <h4 className="card-title fw-bold fs-1">99+</h4>
                                    <p className="card-text">Projects Completed</p>
                                </div>
                            </div>

                        </motion.div>
                        <motion.div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                        >
                            <div className="card bg-transparent text-center text-white border-0">
                                <div className="card-body ">
                                    <img className="card-img" src={congrats} alt="Title" style={{ height: '70px', width: '70px' }} />
                                    <h4 className="card-title fw-bold fs-1">1k</h4>
                                    <p className="card-text">History of Achievers</p>
                                </div>
                            </div>

                        </motion.div>
                        <motion.div className="col-lg-3 col-md-6 col-sm-6 col-12 p-3"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.73 }}
                            viewport={{ once: true }}
                        >
                            <div className="card bg-transparent text-center text-white border-0">
                                <div className="card-body ">
                                    <img className="card-img" src={ribbon} alt="Title" style={{ height: '70px', width: '70px' }} />
                                    <h4 className="card-title fw-bold fs-1">1</h4>
                                    <p className="card-text">Awards and Achievements</p>
                                </div>
                            </div>

                        </motion.div>
                    </div>

                </div>
            </div>


            <div className="section choose-section " style={{ backgroundColor: '#f5f3f2', minHeight: '100vh' }}>
                <div className="container">
                    <div
                        className="row justify-content-center align-items-center g-2"
                    >
                        <div className="col-lg-6 col-md-12">
                            <p className="col-lg-8 col-xl-7 col-6 fw-bold fs-5 text-center choices"
                            ><span style={{ backgroundColor: '#f5f3f2' }}>Why choose Us</span>
                            </p>

                            <motion.h3 className='about_mission'
                                style={{ fontSize: '42px', lineHeight: '1.2' }}
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.90 }}
                                viewport={{ once: true }}
                            >Find More About Us E-learning <span className='text-decoration-underline' style={{ color: '#FFB704' }}>Experience</span></motion.h3>
                            <motion.div className="card-body text-center d-flex p-1 mx-auto"
                                style={{ height: '45vh', width: '38vw' }}
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                                transition={{ duration: 0.70 }}
                                viewport={{ once: true }}
                            >
                                <img className="card-img rounded" src={students} alt="Title" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                            </motion.div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div
                                className="row justify-content-center align-items-center g-2 p-3"
                            >
                                <motion.div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.50 }}
                                    viewport={{ once: true }}
                                >
                                    <div className="card text-start border-0" style={{ height: '45vh', boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}>
                                        <div className="card-body pt-4">
                                            <img className="card-img-top mb-4" src={why2} alt="Title" style={{ height: '40px', width: '40px' }} />
                                            <h4 className="card-title">Flexible Learning</h4>
                                            <p className="card-text">Providing multiple means of engage representation, and expression for all students to learn</p>
                                        </div>
                                    </div>

                                </motion.div>
                                <motion.div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.90 }}
                                    viewport={{ once: true }}
                                >
                                    <div className="card text-start border-0" style={{ height: '45vh', boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}>
                                        <div className="card-body pt-4">
                                            <img className="card-img-top mb-4" src={why3} alt="Title" style={{ height: '40px', width: '40px' }} />
                                            <h4 className="card-title">Learn Anything</h4>
                                            <p className="card-text">Providing multiple means of engage representation, and expression for all students to learn.</p>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.80 }}
                                    viewport={{ once: true }}
                                >
                                    <div className="card text-start border-0" style={{ height: '45vh', boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}>
                                        <div className="card-body pt-4">
                                            <img className="card-img-top mb-4" src={why4} alt="Title" style={{ height: '40px', width: '40px' }} />
                                            <h4 className="card-title">Competitive Pricing</h4>
                                            <p className="card-text">Providing multiple means of engage representation, and expression for all students to learn.</p>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1.00 }}
                                    viewport={{ once: true }}
                                >
                                    <div className="card text-start border-0" style={{ height: '45vh', boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}>
                                        <div className="card-body pt-4">
                                            <img className="card-img-top mb-4" src={why5} alt="Title" style={{ height: '40px', width: '40px' }} />
                                            <h4 className="card-title">Lifetime access</h4>
                                            <p className="card-text">Providing multiple means of engage representation, and expression for all students to learn.</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="faqs-section  g-2 mb-2 " id='faqs' style={{ backgroundColor: '#f5f5f5' }}>
                <div className="container">
                    <div
                        className="row justify-content-between align-items-start g-2"
                    >
                        <div className="col-12 col-md-5 rounded py-5" >
                            <img className='img-fluid  ' src={faqs} alt="info" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px' }} />

                        </div>
                        <div className="col-12 col-md-6  text-start ">
                            <motion.p className="col-10 faq fs-3 fw-bold m-0 "
                                style={{ color: '#FFB704' }}
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.93 }}
                                viewport={{ once: true }}
                            ><span >FAQs</span></motion.p>
                            <motion.p className='about_mission'
                                style={{ fontSize: '42px', lineHeight: '1.2' }}
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.83 }}
                                viewport={{ once: true }}
                            >Frequently Asked Questions</motion.p>

                            <div className="card border-0 bg-transparent">

                                <div className=" card-body  bg-white p-0 my-2 " onClick={() => handleCollapse('card1')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                    <div className="mt-3 ms-3 "> <p className='m-0 fw-bold' style={{ color: currentCard === 'card1' ? '#FFB704' : '' }}> 1. &nbsp; Why choose us for your education?
                                    </p></div>
                                    <div className="p-3 pt-0 col-12 ">
                                        <div className="row">

                                            <div className={`col-lg-12 faqs_card ${currentCard === 'card1' ? 'expandCard' : 'collapseCard'}`} >
                                                <p className='ms-3'>
                                                    We unleash your potential with personalized online tutoring. Our dedicated educators tailor lessons to your needs, fostering a dynamic learning experience that ensures academic excellence.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" card-body  bg-white  p-0 my-2" onClick={() => handleCollapse('card2')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                    <div className="mt-3 ms-3 "> <p className='m-0 fw-bold' style={{ color: currentCard === 'card2' ? '#FFB704' : '' }}> 2. &nbsp; What We Offer To You?
                                    </p></div>
                                    <div className="p-3 pt-0 col-12 ">
                                        <div className="row">

                                            <div className={`col-lg-12 faqs_card ${currentCard === 'card2' ? 'expandCard' : 'collapseCard'}`} >
                                                <p className='ms-3'>We elevate your education with diverse subjects and personalized instruction. Access top-notch educators worldwide and embark on a journey of academic enrichment. Uncover a world of knowledge designed just for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" card-body  bg-white  p-0 my-2" onClick={() => handleCollapse('card3')} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}>
                                    <div className="mt-3 ms-3 "> <p className='m-0 fw-bold' style={{ color: currentCard === 'card3' ? '#FFB704' : '' }}> 3. &nbsp; How We Provide Services For You?
                                    </p></div>

                                    <div className="p-3 pt-0 col-12 ">
                                        <div className="row">

                                            <div className={`col-lg-12 faqs_card ${currentCard === 'card3' ? 'expandCard' : 'collapseCard'}`} >
                                                <p className='ms-3'>We prioritize your success. From tailored lessons to expert guidance, we ensure a seamless learning experience. Join us to explore, learn, and achieve your educational goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row justify-content-around align-items-start g-2 m-5" style={{ minHeight: '70vh' }}>

                <div className="col-lg-4 col-12  ">
                    <div className="card text-start border-0" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px' }}>
                        <div className="card-body">
                            <img className="card-img-top" src={target} alt="Title" style={{ height: '80px', width: '90px' }} />
                            <h4 className="card-title fs-2">Support and Assistance</h4>
                            <p className=''>Elevate your learning journey with our dedicated tutors, ensuring excellence and innovation in every session.
                                Join us for a brighter educational future!"</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 p-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px' }}>
                    <h4 className="fs-2 mb-3">Feel free to get in touch Contact Us.</h4>

                    <form onSubmit={(e) => handleComment(e)}>
                        <div className="mb-3  row">
                            <div className="col-12 col-md-6">
                                <label className="form-label mb-3">Your Username*</label>
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    name=""
                                    aria-describedby="emailHelpId"
                                    placeholder="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <label className="form-label mb-3">Your Email*</label>
                                <input
                                    type="email"
                                    className="form-control rounded-0"
                                    name=""
                                    aria-describedby="emailHelpId"
                                    placeholder="e-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label mb-3">Your Comment</label>
                            <textarea className="form-control rounded-0" name="comment" value={content} onChange={(e) => setContent(e.target.value)} rows="3"></textarea>

                        </div>
                        <div className="mb-3">
                            <button type='submit' className="btn btn-danger col-12 rounded-0">Send</button>
                        </div>
                    </form>

                </div>
            </div>



            <div className="footer footer-section  mt-4  px-4">
                <div className="row justify-content-around align-items-center g-2">
                    <div className="section_footer row justify-content-around align-items-center g-2 py-md-5 my-md-5">
                        <div className="col-lg-5 col-md-4 footer_logo">
                            <motion.p
                                className="fs-3 fw-bold"
                                initial={{ opacity: 0, y: -80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.73 }}
                                viewport={{ once: true }}
                            ><NavLink className='footer_logo' to="/">Knowledge <span style={{ color: '#FFB704' }}>Quest</span> Tutors </NavLink></motion.p>
                            <motion.p
                                initial={{ opacity: 0, y: -80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.53 }}
                                viewport={{ once: true }}
                            > The use of vibrant colors and intuitive layout aims to make the learning journey enjoyable, while responsive design guarantees a consistent experience across devices.</motion.p>
                            <motion.ul
                                className="d-flex list-unstyled justify-content-start gap-3"
                                initial={{ opacity: 0, y: -80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                                viewport={{ once: true }}
                            >

                                <li><FontAwesomeIcon icon={faFacebook} style={{ color: "#b0b0b0", }} /></li>
                                <li><FontAwesomeIcon icon={faXTwitter} style={{ color: "#b0b0b0", }} /></li>
                                <li><FontAwesomeIcon icon={faYoutube} style={{ color: "#bobobo", }} /></li>
                            </motion.ul>
                        </div>
                        <div className="col-lg-2 col-md-2  text-start ">
                            <p className="fw-bold footer_help">Useful Links</p>
                            <ul className='footer-ul p-0'>
                                <li><NavLink className="navbar-brand " to="/about">About Us </NavLink></li>
                                <li><NavLink className="navbar-brand " to="#">Blog </NavLink> </li>
                                <li><NavLink className="navbar-brand " to="/about">Contact Us </NavLink> </li>
                                <li><NavLink className="navbar-brand " to="/pricing">Pricing </NavLink> </li>
                                <li><NavLink className="navbar-brand " to="/reviews">Testimonials </NavLink></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-2 text-start ">
                            <p className="fw-bold footer_help">Courses</p>
                            <ul className='footer-ul p-0'>
                                <li>Business & Finance</li>
                                <li>Computer Science</li>
                                <li>Economics</li>
                                <li>History</li>
                                <li>Writing</li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-2 text-start">
                            <p className="fw-bold   footer_help">Support</p>
                            <ul className='footer-ul   p-0'>
                                <li onClick={() => navigate('/contact')}>Help Center </li>
                                <li onClick={() => navigate('/contact')}>Contact Us</li>
                                <li>Terms of Service </li>
                                <li>Legal </li>
                                <li>Privacy Policy </li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="col-12">
                        <p>All Rights reserved &copy; 2024 KnowledgeQuestTutor.org &nbsp;</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default About