import React, { useEffect, useState } from "react";
import lecturer from "../../Assets/img/lecturer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faPlus,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import student from "../../Assets/icons/student.png";
import { ArrowRight } from "react-bootstrap-icons";
import "./Home.css";
import library from "../../Assets/icons/stack-of-books.png";
import process1 from "../../Assets/img/process1.png";
import process2 from "../../Assets/img/process2.png";
import process3 from "../../Assets/img/process3.png";
import { motion } from "framer-motion";
import accounting from "../../Assets/img/accounting.png";
import computer from "../../Assets/img/computer.png";
import humanity from "../../Assets/img/humanity.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Home({ openSignup, loading, children }) {
  const navigate = useNavigate();

  return (
    <div className="wrapper">
      <div
        className="hero-section p-0 bg-light"
        style={{ backgroundColor: "#E8F5E9" }}
      >
        <div className="container p-0 ">
          <div className="p-0 ">
            <div
              className="row justify-content-around align-items-center  mx-0"
              style={{ position: "relative" }}
            >
              <div className="col-lg-6  col-md-6   col-sm-12 text-start order-2 order-md-1 mb-lg-5">
                {loading ? (
                  <>
                    <Skeleton width={40} />
                  </>
                ) : (
                  <motion.p
                    className="col-8 online text-end"
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.53 }}
                  >
                    <span className="bg-light">Online Writing Services</span>
                  </motion.p>
                )}
                {loading ? (
                  <>
                    <Skeleton width={40} />
                  </>
                ) : (
                  <motion.p
                    className="m-0 hero-p"
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.73 }}
                  >
                    Ensuring top-notch Projects{" "}
                    <span className="academic">completion</span> within your
                    time constraints.
                  </motion.p>
                )}
                {loading ? (
                  <>
                    <Skeleton width={40} />
                  </>
                ) : (
                  <motion.p
                    className="fs-small"
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.73 }}
                    viewport={{ once: true }}
                  >
                    "Let's elevate your grades together!"
                  </motion.p>
                )}

                <div className="d-flex justify-content-start align-items-center col-xl-7  gap-2">
                  <div className="col-6 col-xl-6  mb-5 mb-sm-0">
                    {loading ? (
                      <>
                        <Skeleton width={40} />
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          navigate("/question");
                          openSignup();
                        }}
                        className="btn fw-light"
                        style={{ backgroundColor: "#FFB704", color: "#011955" }}
                      >
                        Get Started Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 p-0 order-1  order-md-2"
                style={{ position: "relative" }}
              >
                {loading ? (
                  <>
                    <Skeleton className="card px-2 border-0 d-none d-lg-flex" />
                  </>
                ) : (
                  <motion.div
                    className="card px-2 border-0 d-none d-lg-flex"
                    style={{
                      position: "absolute",
                      top: "50%",
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                    }}
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.73 }}
                    viewport={{ once: true }}
                  >
                    {loading ? (
                      <>
                        <Skeleton className="card-body" />
                      </>
                    ) : (
                      <div className="card-body text-center ">
                        <img
                          className="card-img "
                          src={student}
                          alt="Title"
                          style={{ height: "60px", width: "80px" }}
                        />
                        <p className="card-title m-0 fw-bold fs-5">
                          Student <br />
                          Enrolls
                        </p>
                        <p className="card-text fs-2 fw-bold ">99%</p>
                      </div>
                    )}
                  </motion.div>
                )}

                {loading ? (
                  <>
                    <Skeleton className="img-div m-0 p-0 col-12" height={300} />
                  </>
                ) : (
                  <div
                    className="img-div m-0 p-0 col-12 "
                    style={{ height: "80vh" }}
                  >
                    <img
                      className="heroImg img-fluid "
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      src={lecturer}
                      alt="info"
                    />
                  </div>
                )}

                <motion.div
                  className="card px-2 pe-4 py-4 border-0 d-none d-lg-flex"
                  style={{
                    position: "absolute",
                    top: "80%",
                    left: "40%",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                  }}
                  initial={{ opacity: 0, scale: 0, y: 300 }}
                  whileInView={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ duration: 0.53 }}
                  viewport={{ once: true }}
                >
                  {loading ? (
                    <>
                      <Skeleton className="card-body" />
                    </>
                  ) : (
                    <div className="card-body text-center d-flex align-items-center gap-3">
                      <p
                        className="border m-0 border-black"
                        style={{ borderRadius: "50%" }}
                      >
                        <FontAwesomeIcon className="m-2" icon={faUserPlus} />
                      </p>
                      <p className="m-0">50+ Tutors</p>
                      <div
                        className="d-flex px-1 align-items-center"
                        style={{ position: "relative" }}
                      >
                        <p
                          className="border border-black bg-info m-0"
                          style={{ borderRadius: "50%", position: "absolute" }}
                        >
                          {" "}
                          <FontAwesomeIcon className="m-2" icon={faUserTie} />
                        </p>
                      </div>
                    </div>
                  )}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="library-section mb-3 mb-sm-0">
        <div className="container">
          <div className=" row justify-content-between align-items-center g-2">
            {loading ? (
              <>
                <div className="row">
                  <Skeleton className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2" />
                  <Skeleton className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2" />
                  <Skeleton className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2" />
                </div>
              </>
            ) : (
              <>
                <motion.div
                  className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2"
                  initial={{
                    opacity: 0,
                    rotateX: 180,
                    transformOrigin: "center",
                  }}
                  whileInView={{ opacity: 1, rotateX: 0 }}
                  transition={{ duration: 0.83 }}
                  viewport={{ once: true }}
                >
                  <div
                    className="card border-0 py-2 mt-sm-3"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                    }}
                  >
                    <div className="card-body text-center d-flex gap-2">
                      <div
                        className="div-img p-2 rounded "
                        style={{ backgroundColor: "#FDE79F" }}
                      >
                        <img
                          className="card-img"
                          src={library}
                          alt="Title"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div className="text-start">
                        <p className="card-title m-0 fw-bold ">
                          Book Library and Store
                        </p>
                        <button
                          className="btn  p-0"
                          onClick={() => navigate("/courses")}
                        >
                          View more <ArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2"
                  initial={{
                    opacity: 0,
                    rotateX: 180,
                    transformOrigin: "center",
                  }}
                  whileInView={{ opacity: 1, rotateX: 0 }}
                  transition={{ duration: 0.83 }}
                  viewport={{ once: true }}
                >
                  <div
                    className="card border-0 py-2"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                    }}
                  >
                    <div className="card-body text-center d-flex gap-2">
                      <div
                        className="div-img p-2 rounded "
                        style={{ backgroundColor: "#FDE79F" }}
                      >
                        <img
                          className="card-img"
                          src={library}
                          alt="Title"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div className="text-start">
                        <p className="card-title m-0 fw-bold ">
                          Book Library and Store
                        </p>
                        <button
                          className="btn  p-0"
                          onClick={() => navigate("/courses")}
                        >
                          View more <ArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className="col-lg-4 col-md-4 col-12 p-lg-3 p-md-2"
                  initial={{
                    opacity: 0,
                    rotateX: 180,
                    transformOrigin: "center",
                  }}
                  whileInView={{ opacity: 1, rotateX: 0 }}
                  transition={{ duration: 0.83 }}
                  viewport={{ once: true }}
                >
                  <div
                    className="card border-0 py-2"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
                    }}
                  >
                    <div className="card-body text-center d-flex gap-2">
                      <div
                        className="div-img p-2 rounded "
                        style={{ backgroundColor: "#FDE79F" }}
                      >
                        <img
                          className="card-img"
                          src={library}
                          alt="Title"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div className="text-start">
                        <p className="card-title m-0 fw-bold ">
                          Book Library and Store
                        </p>
                        <button
                          className="btn  p-0"
                          onClick={() => navigate("/courses")}
                        >
                          View more <ArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="cat-section  section d-flex  align-items-center bg-light  mx-auto d-none d-sm-flex "
        style={{ height: "90vh" }}
      >
        <div
          className="container  rounded d-flex justify-content-center align-items-center g-2"
          style={{ backgroundColor: "#002935", height: "70vh" }}
        >
          <div className="row d-flex justify-content-center align-items-center g-2 ">
            <div className="col-lg-4 col-md-6  col-sm-6 col-12   px-5">
              <div className="row justify-content-start align-items-center g-2">
                <motion.div
                  className="col-8 categories"
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.43 }}
                  viewport={{ once: true }}
                >
                  <span>Categories</span>
                </motion.div>
                <motion.p
                  className="fs-2 fw-bold text-white"
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.73 }}
                  viewport={{ once: true }}
                >
                  Featured Topics by Category
                </motion.p>
                <button
                  onClick={() => navigate("/courses")}
                  className="btn col-lg-7 col-md-7 col-7 bg-white "
                >
                  All categories <ArrowRight />
                </button>
              </div>
            </div>
            <div
              className="card col-md-6 col-sm-6 col-12 text-center p-3 px-1 m-3 d-lg-none "
              style={{ height: "30vh", width: "25vw" }}
            >
              <img
                className="card-img-top align-self-center"
                src={accounting}
                alt="Title"
                style={{ height: "60px", width: "60px" }}
              />
              <div className="card-body">
                <h4 className="card-title">Business Accounts</h4>
                <p className="card-text">
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="lg"
                    style={{ color: "#FFB704" }}
                  />
                </p>
              </div>
            </div>
            <div
              className="card col-md-6 col-sm-6 col-12 text-center p-3 px-1 m-3 d-lg-none "
              style={{ height: "30vh", width: "25vw" }}
            >
              <img
                className="card-img-top align-self-center"
                src={computer}
                alt="Title"
                style={{ height: "60px", width: "60px" }}
              />
              <div className="card-body">
                <h4 className="card-title">Computer Science</h4>
                <p className="card-text">
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="lg"
                    style={{ color: "#FFB704" }}
                  />
                </p>
              </div>
            </div>
            <div
              className="card col-md-6 col-sm-6 col-12 text-center p-3 px-1 m-3 d-lg-none "
              style={{ height: "30vh", width: "25vw" }}
            >
              <img
                className="card-img-top align-self-center"
                src={humanity}
                alt="Title"
                style={{ height: "60px", width: "60px" }}
              />
              <div className="card-body">
                <h4 className="card-title">Humanity</h4>
                <p className="card-text">
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="lg"
                    style={{ color: "#FFB704" }}
                  />
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-5  d-flex align-items-center justify-content-center d-none d-lg-flex"
              style={{ height: "60vh", width: "50vw", position: "relative" }}
            >
              <div
                className="card col-md-5 text-center p-3 px-1 m-3 d-none d-lg-flex"
                style={{ height: "45vh", width: "18vw" }}
              >
                <img
                  className="card-img-top align-self-center"
                  src={accounting}
                  alt="Title"
                  style={{ height: "60px", width: "60px" }}
                />
                <div className="card-body">
                  <h4 className="card-title">Business Accounts</h4>
                  <p className="card-text">
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      style={{ color: "#FFB704" }}
                    />
                  </p>
                </div>
              </div>

              <div
                className="col-6 d-none d-lg-block"
                style={{
                  position: "absolute",
                  height: "100vh",
                  right: "-5vh",
                  top: "-15vh",
                }}
              >
                <div
                  className="card  text-center p-3 px-1 m-3"
                  style={{
                    height: "45vh",
                    width: "18vw",
                    BoxShaddow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                  }}
                >
                  <img
                    className="card-img-top align-self-center"
                    src={computer}
                    alt="Title"
                    style={{ height: "60px", width: "60px" }}
                  />
                  <div className="card-body">
                    <h4 className="card-title">Computer Science</h4>
                    <p className="card-text">
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        style={{ color: "#FFB704" }}
                      />
                    </p>
                  </div>
                </div>
                <div
                  className="card  text-center p-3 px-1 m-3"
                  style={{
                    height: "45vh",
                    width: "18vw",
                    BoxShaddow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                  }}
                >
                  <img
                    className="card-img-top align-self-center"
                    src={humanity}
                    alt="Title"
                    style={{ height: "60px", width: "60px" }}
                  />
                  <div className="card-body">
                    <h4 className="card-title">Humanity</h4>
                    <p className="card-text">
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        style={{ color: "#FFB704" }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-section  py-sm-4 py-5">
        <div className="container ">
          <div className="col-6 mx-auto text-center ">
            <h1 className="steps">
              <span> How it Work</span>
            </h1>
            <motion.p
              className="fs-1"
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              Check How We Work <br />
              in Easy Steps
            </motion.p>
          </div>
          <div className="row justify-content-center gap-md-4 d-flex  m-0 g-2 ">
            <motion.div
              className="col-lg-3 col-md-3 col-6  py-3 text-center "
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="img-div" style={{ position: "relative" }}>
                <img className="img-fluid" src={process1} alt="info" />
                <p
                  className="step lh-1  rounded-circle  fs-4"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  01
                </p>
              </div>
              <p className="fw-bold">Post A Question</p>
              <p>Ask any type of question you want</p>
            </motion.div>
            <motion.div
              className="col-lg-3 col-md-3 col-6  py-3 text-center "
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="img-div" style={{ position: "relative" }}>
                <img className="img-fluid" src={process2} alt="info" />
                <p
                  className="step lh-1  rounded-circle  fs-4"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  02
                </p>
              </div>
              <p className="fw-bold">Connect with Tutor</p>
              <p>
                Our system recommends tutors specialized in that discipline.
              </p>
            </motion.div>
            <motion.div
              className="col-lg-3 col-md-3 col-6  py-3 text-center "
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="img-div" style={{ position: "relative" }}>
                <img className="img-fluid" src={process3} alt="info" />
                <p
                  className="step lh-1  rounded-circle  fs-4"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  03
                </p>
              </div>
              <p className="fw-bold">Response</p>
              <p>You receive quality explanations & answers.</p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="review-section py-4 mb-5 mb-sm-0">
        <div className="container">
          <div className="col-6 mx-auto text-center ">
            <h1 className="steps">
              <span>Reviews</span>
            </h1>
          </div>
          <motion.div
            className="col-12"
            style={{ position: "relative" }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.93 }}
            viewport={{ once: true }}
          >
            {children}
          </motion.div>
        </div>
      </div>

      <div className="footer footer-section  mt-5  px-4">
        <div className="row justify-content-around align-items-center g-2">
          <div className="section_footer row justify-content-around align-items-center g-2 py-md-5 my-md-5">
            <div className="col-lg-5 col-md-4 footer_logo">
              <motion.p
                className="fs-3 fw-bold"
                initial={{ opacity: 0, y: -80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.73 }}
                viewport={{ once: true }}
              >
                <NavLink className="footer_logo" to="/">
                  Knowledge <span style={{ color: "#FFB704" }}>Quest</span>{" "}
                  Tutors{" "}
                </NavLink>
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: -80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.53 }}
                viewport={{ once: true }}
              >
                {" "}
                The use of vibrant colors and intuitive layout aims to make the
                learning journey enjoyable, while responsive design guarantees a
                consistent experience across devices.
              </motion.p>
              <motion.ul
                className="d-flex list-unstyled justify-content-start gap-3"
                initial={{ opacity: 0, y: -80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                viewport={{ once: true }}
              >
                <li>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "#b0b0b0" }}
                  />
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ color: "#b0b0b0" }}
                  />
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "#bobobo" }}
                  />
                </li>
              </motion.ul>
            </div>
            <div className="col-lg-2 col-md-2  text-start ">
              <p className="fw-bold footer_help">Useful Links</p>
              <ul className="footer-ul p-0">
                <li>
                  <NavLink className="navbar-brand " to="/about">
                    About Us{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-brand " to="#">
                    Blog{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink className="navbar-brand " to="/about">
                    Contact Us{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink className="navbar-brand " to="/pricing">
                    Pricing{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink className="navbar-brand " to="/reviews">
                    Testimonials{" "}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-2 text-start ">
              <p className="fw-bold footer_help">Courses</p>
              <ul className="footer-ul p-0">
                <li>Business & Finance</li>
                <li>Computer Science</li>
                <li>Economics</li>
                <li>History</li>
                <li>Writing</li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2 text-start">
              <p className="fw-bold   footer_help">Support</p>
              <ul className="footer-ul   p-0">
                <li onClick={() => navigate("/contact")}>Help Center </li>
                <li onClick={() => navigate("/contact")}>Contact Us</li>
                <li>Terms of Service </li>
                <li>Legal </li>
                <li>Privacy Policy </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="col-12">
            <p>
              All Rights reserved &copy; 2024 KnowledgeQuestTutor.org &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
