import React, { useEffect, useState, useContext } from "react";
import { topicsArr } from "../../Assets/arrayData/topicsArr";
import { subjectsArr } from "../../Assets/arrayData/subjectsArr";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./Question.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Toaster } from "react-hot-toast";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router";
import { CableContext } from "../../context/cable";
import { UrlContext } from "../../context/url";
import Payment from "../../components/Payment/Payment";

function Question({ user, isLogged, setRequests, requests, children }) {
  const { apiUrl } = useContext(UrlContext);
  const cable = useContext(CableContext);
  const navigate = useNavigate();
  const [wordsPerPage, setwordsPerPage] = useState(275);
  const [error, setError] = useState("");
  const [question_id, setquestion_id] = useState("");
  // form states
  const [topic, setTopic] = useState("");
  const [subject, setSubject] = useState("");
  const [files, setFiles] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState(new Date());
  const [qDetails, setQDetails] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [singleSpaced, setsingleSpaced] = useState(false);
  const [deadline, setDeadline] = useState("");
  const [budget, setBudget] = useState("0");

  const [clientSecret, setClientSecret] = useState("");
  const [checkoutDispOverlay, setCheckoutOverlay] = useState(false);
  const [isLoadingQ, setIsLoadingQ] = useState(false);

  const [clientToken, setClientToken] = useState(null);
  const [paymentMethodNonce, setPaymentMethodNonce] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOrderSection, setselectedOrderSection] = useState("basic");
  // pricing
  const [sixHrs, setSixHrs] = useState("");
  const [twelveHrs, setTwelveHrs] = useState("");
  const [twentyFour, setTwentyFour] = useState("");
  const [twoDays, setTwoDays] = useState("");
  const [threeDays, setThreeDays] = useState("");
  const [fiveDays, setFiveDays] = useState("");
  const [sevenDays, setSevenDays] = useState("");
  const [nineDays, setNineDays] = useState("");
  const [fourteenDays, setFourteenDays] = useState("");
  const [twentyDays, setTwentyDays] = useState("");

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });

  const handlePageCount = (operator) => {
    if (pageCount > 0) {
      let count = pageCount;
      if (operator === "+") {
        count += 1;
      } else if (operator === "-" && pageCount > 1) {
        count -= 1;
      }
      setPageCount(count);
    }
  };

  useEffect(() => {
    if (singleSpaced) {
      setwordsPerPage(550);
    } else {
      setwordsPerPage(275);
    }
  }, [singleSpaced, wordsPerPage]);

  useEffect(() => {
    if (deadline !== "") {
      console.log("DEAD", deadline);

      const str = deadline.match(/[a-zA-Z]+/).toString();
      const num = parseInt(deadline.match(/\d+/)[0]);
      const currentdateTime = new Date();
      if (str === "hrs") {
        currentdateTime.setHours(currentdateTime.getHours() + num);
        console.log("first", currentdateTime);
        setDeliveryTime(() => currentdateTime);
      } else if (str === "days") {
        currentdateTime.setHours(currentdateTime.getHours() + num * 24);
        console.log("first", currentdateTime);
        setDeliveryTime(() => currentdateTime);
      }
    }
  }, [deadline]);

  useEffect(() => {
    if (deadline) {
      const num = parseInt(deadline.match(/\d+/)[0]);
      const multiplier = singleSpaced ? 2 : 1;
      const updateBudget = (timePeriod) => {
        const newBudget = multiplier * pageCount * timePeriod;
        const newBudgetString = newBudget.toString();
        setBudget(newBudgetString);
      };
      switch (num) {
        case 6:
          updateBudget(25);
          break;
        case 12:
          updateBudget(22);
          break;
        case 24:
          updateBudget(20);
          break;
        case 2:
          updateBudget(18);
          break;
        case 3:
          updateBudget(15);
          break;
        case 5:
          updateBudget(14);
          break;
        case 7:
          updateBudget(13);
          break;
        case 9:
          updateBudget(12);
          break;
        case 14:
          updateBudget(11);
          break;
        case 20:
          updateBudget(10);
          break;
        default:
          updateBudget(0);
      }
    }
  }, [pageCount, deadline, singleSpaced]);

  // methods for the quills textarea

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const content = quillRef.current?.firstChild?.innerHTML;
        setQDetails(content);
      });
    }
  }, [quill, quillRef]);

  const fileTypes = ["JPG", "PDF", "PNG", "GIF", "DOC"];

  const handleChange = (files) => {
    setFiles(files);
  };

  // const handleFileUpload = (e) =>{
  //   console.log(e.target.files[0])
  //   setFile(e.target.files[0])

  // }

  function handleSubmitQuestion(e) {
    e.preventDefault();
    console.log("START Q");
    setError("");

    // Check for missing fields
    if (!topic) {
      setError("Topic is required");
      return;
    } else if (!subject) {
      setError("Subject is required");
      return;
    } else if (qDetails.length < 100 || !qDetails) {
      setError(
        "Question Description is too short, Make at least 100 characters"
      );
      return;
    } else if (deadline === "Select one") {
      setError("Invalid Delivery Time");
      return;
    } else if (budget <= 0) {
      setError("set Deadline");
      return;
    } else if (!user) {
      setError("signIn first");
      return;
    }

    const formData = new FormData();

    // *Add non-file data to FormData
    formData.append("user_id", user.id);
    formData.append("subject", subject);
    formData.append("details", qDetails);
    formData.append("topic", topic);
    formData.append("page_count", pageCount);
    formData.append("delivery_time", deliveryTime);
    formData.append("budget", budget);
    formData.append("payment_status", "pending");
    formData.append("order_status", "pending");

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
    }
    // Call the sendQuestion function with the FormData object
    sendQuestion(formData);
  }

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 6);
    const six = currentDate.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setSixHrs(six);

    const currentDate1 = new Date();
    currentDate1.setHours(currentDate1.getHours() + 12);
    const twelve = currentDate1.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setTwelveHrs(twelve);

    const currentDate2 = new Date();
    currentDate2.setHours(currentDate2.getHours() + 24);
    const twenty = currentDate2.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setTwentyFour(twenty);

    const currentDate3 = new Date();
    currentDate3.setHours(currentDate3.getHours() + 24 * 2);
    const two = currentDate3.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setTwoDays(two);

    const currentDate4 = new Date();
    currentDate4.setHours(currentDate4.getHours() + 24 * 3);
    const three = currentDate4.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setThreeDays(three);

    const currentDate8 = new Date();
    currentDate8.setHours(currentDate8.getHours() + 24 * 5);
    const five = currentDate8.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setFiveDays(five);

    const currentDate5 = new Date();
    currentDate5.setHours(currentDate5.getHours() + 24 * 7);
    const seven = currentDate5.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setSevenDays(seven);

    const currentDate6 = new Date();
    currentDate6.setHours(currentDate6.getHours() + 24 * 9);
    const nine = currentDate6.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setNineDays(nine);

    const currentDate7 = new Date();
    currentDate7.setHours(currentDate7.getHours() + 24 * 14);
    const fourteen = currentDate7.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setFourteenDays(fourteen);

    const currentDate9 = new Date();
    currentDate9.setHours(currentDate9.getHours() + 24 * 20);
    const twoFour = currentDate9.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    });
    setTwentyDays(twoFour);
  }, []);

  useEffect(() => {
    // !Braintree function
    const token = localStorage.getItem("jwt");
    fetch(apiUrl + "/payments/new", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("DATA", data);

        if (data.client_token) {
          setClientToken(data.client_token);
        } else {
          throw new Error("Client token not received");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  //! sendQuestion
  async function sendQuestion(formData) {
    console.log("sending ...");

    fetch(apiUrl + "/questions", {
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        if (!resp.ok) {
          throw new Error("Network response was not ok");
        }
        return resp.json();
      })
      .then((data) => {
        setRequests([...requests, data]);
        setquestion_id(data.id);
        console.log("Cont ...");
        // if (cable) {
        //   cable.subscriptions.create(
        //     {
        //       channel: "QuestionsChannel",
        //     },
        //     {
        //       received: (request) => {
        //         setRequests([...requests, request]);
        //       },
        //     }
        //   );
        // } else if (!cable) {
        //   console.error("Action Cable connection not initialized");
        //   return; // Exit early if cable is not initialized
        // }
      });
  }

  // Render the child component with the additional data
  const childWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      clientSecret: clientSecret,
    })
  );

  const handlePaymentSuccess = (nonce) => {
    // Handle the payment success, e.g., send the nonce to your server for further processing
    setPaymentMethodNonce(nonce);
  };

  return (
    <div
      className="wrapper "
      style={{ backgroundColor: "#f5f3f2 ", height: "80vh" }}
    >
      <div className="container pt-5">
        <Toaster />
        {error && 
        <p className="m-0 text-danger">{error}</p>
        }
        <div className="row justify-content-center align-items-start g-2">
          <div className="col-12 col-md-8">
            {selectedOrderSection === "basic" && (
              <>
                <h1 className="fs-4 fw-bold">
                  Select your type of work and deadline
                </h1>
                <div className="card text-start">
                  <div className="card-body">
                    <div className="row justify-content-start align-items-start g-2">
                      <div className="col-5">
                        <label className="form-label ">
                          Type of work<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select "
                          onChange={(e) => setTopic(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select one
                          </option>
                          {topicsArr.map((element, index) => (
                            <option key={index} value={element}>
                              {element}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-5">
                        <label className="form-label  ">
                          Subject <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select "
                          name=""
                          id=""
                          onChange={(e) => setSubject(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select one
                          </option>
                          {topicsArr.map((element, index) => (
                            <option key={index} value={element}>
                              {element}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-5">
                        <label for="" className="form-label">
                          Deadline
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setDeadline(e.target.value);
                          }}
                          value={deadline}
                        >
                          <option value="">Select </option>
                          <option value="6 hrs">6 hrs / {sixHrs} </option>
                          <option value="12 hrs">12 hours / {twelveHrs}</option>
                          <option value="24 hrs">
                            24 hours / {twentyFour}
                          </option>
                          <option value="2 days ">2 days / {twoDays}</option>
                          <option value="3 days ">3 days / {threeDays}</option>
                          <option value="5 days ">5 days / {fiveDays}</option>
                          <option value="7 days ">7 days / {sevenDays}</option>
                          <option value="9 days ">9 days / {nineDays}</option>
                          <option value="14 days">
                            14 days / {fourteenDays}
                          </option>
                          <option value="20 days">
                            20 days / {twentyDays}
                          </option>
                        </select>
                      </div>
                      <div className="col-5">
                        <label className=" form-label">Page count</label>
                        <div className="d-flex flex-wrap align-items-center gap-2 col-6 col-sm-12">
                          <div className="card text-start ">
                            <div className="card-body d-flex justify-content-between p-0">
                              <button
                                className="btn col-xs-order-2 col-sm-order-1"
                                onClick={() => handlePageCount("-")}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </button>
                              <button className="btn col-xs-order-1 col-sm-order-2">
                                {pageCount}
                              </button>
                              <button
                                className="btn col-xs-order-3 col-sm-order-3"
                                onClick={() => handlePageCount("+")}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            </div>
                          </div>
                          <div className="text-black  ">
                            <small>{pageCount * wordsPerPage}</small> words
                          </div>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-5">
                        <div className="form-check text-black">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="singleSpaced"
                            id=""
                            checked={singleSpaced}
                            onChange={() =>
                              setsingleSpaced((prevValue) => !prevValue)
                            }
                          />
                          <label className="form-check-label text-black" for="">
                            Single Spaced{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="row justify-content-between align-items-center g-2">
                    <div
                      className={` col-2 col-md-3 d-flex  ${
                        selectedOrderSection === "basic" ? "ms-auto" : ""
                      }`}
                    >
                      <button
                        className="btn btn-primary flex-fill"
                        onClick={() => setselectedOrderSection("instructions")}
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedOrderSection === "instructions" && (
              <>
                <div className="card text-start">
                  <div className="card-body">
                    <div className="row justify-content-center align-items-center g-2">
                      <div className="col-12 text-start ">
                        <p className="fw-bold">Describe Your Question</p>
                        <p className="m-0">
                          <small>
                            <em>
                              Providing detailed and specific information
                              enhances the quality of assistance you'll receive.
                            </em>
                          </small>
                        </p>
                      </div>
                      <div
                        className="col-8 col-md-12"
                        style={{
                          maxHeight: "40vh",
                          height: "40vh",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            height: "30vh",
                            width: "100%",
                            background: "white",
                            color: "black",
                          }}
                        >
                          <div ref={quillRef} />
                        </div>
                      </div>

                      <div className="col-12">
                        <FileUploader
                          multiple
                          handleChange={handleChange}
                          name="file"
                          types={fileTypes}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <div className="row justify-content-between align-items-center g-2">
                    <div className="col-3 col-md-2 d-flex">
                      <button
                        className="btn border bg-white flex-fill"
                        onClick={() => setselectedOrderSection("basic")}
                      >
                        Go back
                      </button>
                    </div>
                    <div className="col-2 col-md-3 d-flex">
                      <button
                        className="btn btn-warning flex-fill"
                        onClick={(e) => {
                          setselectedOrderSection("checkout");
                          handleSubmitQuestion(e);
                        }}
                      >
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedOrderSection === "checkout" && (
              <>
                <div className="card text-start">
                  <div className="card-body">
                    <div className="row justify-content-center align-items-center g-2">
                      <div className="col-12">
                        {paymentMethodNonce ? (
                          <div>
                            <h2>Payment Successful!</h2>
                            <p>Nonce: {paymentMethodNonce}</p>
                          </div>
                        ) : (
                          <Payment
                            onPaymentMethodNonce={handlePaymentSuccess}
                            clientToken={clientToken}
                            loading={loading}
                            amount={budget}
                            question_id={question_id}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 my-3">
                  <div className="row justify-content-between align-items-center g-2">
                    <div className="col-3 col-md-2 d-flex">
                      <button
                        className="btn border bg-white flex-fill"
                        onClick={() => setselectedOrderSection("instructions")}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-12 col-md-4">
            {/* checkout section */}
            <div className="checkout-section">
              <div className="card ">
                <div className="card-body">
                  <h4>Summary</h4>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td colspan="2">Topic</td> <td>{topic}</td>
                      </tr>
                      <tr>
                        <td colspan="2">Subject</td>
                        <td>{subject}</td>
                      </tr>
                      <tr>
                        <td colspan="2">Page Count</td>
                        <td>{pageCount}</td>
                      </tr>
                      <tr>
                        <td colspan="2">Deadline</td>
                        <td>
                          {deliveryTime.toLocaleDateString(undefined, {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-uppercase" colspan="2">
                          Total
                        </td>
                        <td>
                          <span className="text-muted">$</span>
                          {budget}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {checkoutDispOverlay && (
          <div className="checkoutOverlay">{childWithProps}</div>
        )}
      </div>
    </div>
  );
}

export default Question;
