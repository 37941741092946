import React, { useEffect } from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Navbar({
  openSignup,
  openSignin,
  user,
  setUser,
  setLogged,
  isLoggedIn,
  toggleNav,
  openNav,
  loading,
}) {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("kqt_user_id");
    setUser(null);
    setLogged(false);
    navigate("/");
  }

  const handleLogoRoute = (user) => {
    if (user && user.role === "admin") {
      navigate('/admin_home')
    } else if (user && user.role === "writer") {
      navigate('/writer_home')
    }else if (user && user.role === "member") {
      navigate("/member_home")
    }else{
      navigate('/')
    }
  }

  return (
    <div
      className="wrapper bg-white nav-wrap d-flex align-items-center"
      style={{ height: "80px", top: "-10px", zIndex: "9" }}
    >
      <div className="navbar-container container m-0 ">
        <nav className="navbar navbar-expand-md ">
          <div className="container-fluid row">
            {loading ? (
              <>
                <Skeleton height={40} width={100} />
              </>
            ) : (
              <div className="col-lg-6 col-6 p-0  text-center">
                {/* <NavLink className="navbar-brand " onClick={()=>navigate('/')}> </NavLink> */}
                <h3 className="logo" onClick={()=>handleLogoRoute(user)}>
                  Knowledge Quest Tutors
                </h3>
              </div>
            )}
            <div className="d-lg-flex col-lg-7  col-1 justify-content-end  p-0">
              {/* <p>{user.isAdmin}</p> */}
              <button
                onClick={() => toggleNav()}
                className="navbar-toggler p-1 d-lg-none d-flex"
                type="button"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className=" d-none d-lg-flex col-12  row  justify-content-end align-items-center g-2 gap-2 gap-xl-0">
                <div className=" mb-lg-0 mb-2  col-lg-7  ">
                  <ul className="navbar-nav ">
                    {loading ? (
                      <>
                        <Skeleton width={40} />
                      </>
                    ) : (
                      <li className="nav-item d-flex align-items-center">
                        <NavLink to="/pricing" className="nav-link">
                          Prices
                        </NavLink>
                      </li>
                    )}
                    {loading ? (
                      <>
                        <Skeleton width={40} />
                      </>
                    ) : (
                      <li className="nav-item d-flex align-items-center">
                        <NavLink to="/resources" className="nav-link">
                          Resources
                        </NavLink>
                        <p
                          className="m-0 drop-icon fw-light"
                          style={{ fontSize: "14px" }}
                        >
                          +
                        </p>
                      </li>
                    )}
                    {loading ? (
                      <>
                        <Skeleton width={40} />
                      </>
                    ) : (
                      <li className="nav-item">
                        <NavLink to="/about" className="nav-link">
                          About
                        </NavLink>
                      </li>
                    )}
                    {loading ? (
                      <>
                        <Skeleton width={40} />
                      </>
                    ) : (
                      <li className="nav-item d-flex align-items-center">
                        <NavLink to="/reviews" className="nav-link">
                          Reviews
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="section col-lg-4 col-xl-4 ">
                  <div className="row  m-0 ">
                    <div className="col-12 d-flex  gap-1 justify-content-end align-items-center p-0">
                      {!user && (
                        <>
                          {loading ? (
                            <>
                              <Skeleton width={40} />
                            </>
                          ) : (
                            <>
                              <button
                                className=" p-2 border-0 bg-white"
                                onClick={() => openSignin()}
                              >
                                Login
                              </button>
                              <button
                                className="btn p-2"
                                style={{
                                  backgroundColor: "#FFB704",
                                  color: "#000",
                                }}
                                onClick={() => openSignup()}
                              >
                                Get Started
                              </button>
                            </>
                          )}
                        </>
                      )}
                      {user && user.role === "admin" && (
                        <>
                          {loading ? (
                            <>
                              <Skeleton width={40} />
                            </>
                          ) : (
                            <>
                              <NavLink to="/admin_home">
                                <button className="btn p-2">Dashboard</button>
                              </NavLink>
                              <button
                                className="btn p-2"
                                style={{
                                  backgroundColor: "#FFB704",
                                  color: "#fff",
                                }}
                                onClick={() => handleLogout()}
                              >
                                logout
                              </button>
                            </>
                          )}
                        </>
                      )}
                      {user && user.role === "writer" && (  
                        <>
                          {loading ? (
                            <>
                              <Skeleton width={40} />
                            </>
                          ) : (
                            <>
                              <NavLink to="/writer_home">
                                <button className="btn p-2">Profile</button>
                              </NavLink>
                              <button
                                className="btn p-2"
                                style={{
                                  backgroundColor: "#FFB704",
                                  color: "#fff",
                                }}
                                onClick={() => handleLogout()}
                              >
                                logout
                              </button>
                            </>
                          )}
                        </>
                      )}
                      {user && user.role === "member" && (
                        <>
                          <button
                            onClick={() => navigate("/question")}
                            className="btn fw-light"
                            style={{
                              backgroundColor: "#FFB704",
                              color: "black",
                            }}
                          >
                            Order Now
                          </button>
                          <div class="dropdown open ">
                            <button
                              className="btn border-0"
                              type="button"
                              id="triggerId"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                src={user.img}
                                alt="prof"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  border: "1px solid #FFB704",
                                }}
                                className=" img-fluid"
                              />
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-end"
                              aria-labelledby="triggerId"
                            >
                              <p className="m-0 ps-3 ">
                                <span style={{ color: "#002270" }}>Hi,</span>{" "}
                                <span className="text-capitalize text-muted">
                                  {user.username}
                                </span>
                              </p>
                              <p
                                className="btn mb-0 dropdown-item"
                                onClick={() => navigate("/member_home")}
                                style={{ color: "#002270" }}
                              >
                                Profile
                              </p>
                              <button
                                class="dropdown-item"
                                onClick={() => handleLogout()}
                                style={{ color: "#002270" }}
                              >
                                logout
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {
        <div
          className={`sidenav border m-auto d-block d-lg-none py-3 px-0 ${
            openNav ? "visible" : ""
          }`}
        >
          <div className="row  d-flex justify-content-end pe-5">
            <div className="col-1 ">
              <FontAwesomeIcon
                onClick={() => toggleNav()}
                className="btn"
                icon={faX}
                style={{ color: "#ffffff" }}
              />
            </div>
          </div>

          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 col-6 col-md-4 text-center ">
            <li className="nav-item ">
              <NavLink to="/pricing" onClick={() => toggleNav()}>
                Prices
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/resources" onClick={() => toggleNav()}>
                Resources
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/about" onClick={() => toggleNav()}>
                About
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/reviews" onClick={() => toggleNav()}>
                Reviews
              </NavLink>
            </li>
            {!user && (
              <>
                <NavLink onClick={() => openSignup()}>Get Started</NavLink>
                <NavLink onClick={() => openSignin()}>Login</NavLink>
              </>
            )}
            {user && user.role === "admin" && (
              <>
                <NavLink to="/admin_home" onClick={() => toggleNav()}>
                  Dashboard
                </NavLink>
              </>
            )}
            {user && user.role === "writer" && (
              <>
                <NavLink to="/writer_home" onClick={() => toggleNav()}>
                  Dashboard
                </NavLink>
              </>
            )}
            {user && user.role === "member" && (
              <li className="nav-item ">
                <NavLink to="/member_home" onClick={() => toggleNav()}>
                  Profile
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  );
}

export default Navbar;
