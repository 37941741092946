import React, { useState, useContext } from 'react'
import profile from '../../Assets/img/profile.jpg'
import welcome from '../../Assets/img/welcome.png'
import toast, { Toaster } from 'react-hot-toast';
import './Signup.css'
import { useNavigate } from 'react-router'
import {UrlContext} from '../../context/url'

function Signup({ closeSignup, onSignup, setUser, setLoading }) {

    const navigate = useNavigate()
    const  {apiUrl} = useContext(UrlContext)

    const [username, setUsername] = useState("")
    const [school, setSchool] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [error, setError] = useState('');


    async function handleSignupForm(e) {
        e.preventDefault();
        setLoading(true)
        if (username === '' || school === '' || email === '' || password === '' || passwordConfirmation === '') {
            setError('Please fill out all fields');
            return;
        } else if (password !== passwordConfirmation) {
            setError('Passwords does not match')
            return;
        }
        const promise = new Promise((resolve, reject) => {
            fetch(apiUrl + '/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accepts: 'application/json',
                },
                body: JSON.stringify({
                    username,
                    school,
                    email,
                    password,
                    passwordConfirmation
                }),
            })
                .then(resp => resp.json())
                .then(data => {
                    setLoading(false)
                    localStorage.setItem("jwt", data.jwt);
                    localStorage.setItem("kqt_user_id", data.user.id);
                    onSignup(true) //sets is logged to true
                    closeSignup(true)
                    setUser(() => data)
                    resolve()
                    navigate('/')
                })
                .catch(error => {
                    setLoading(false)
                    reject()
                })
        })
        toast.promise(promise, {
            loading: "Creating user ...",
            success: "registered successfully",
            error: "failed. please try again",
        })
    }
    return (
        <div className='signup-section signup-overlay'>
            <div className="container">
                <Toaster />
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-lg-7 col-10 rounded bg-white ">
                        <div className="row justify-content-between align-items-center  ">
                            <div className="col-lg-4 d-lg-flex d-none p-0" style={{ height: '80vh' }}>
                                <div className="signup-img-div" >
                                    <img className='img-fluid' src={welcome} alt="info" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px 0 0 5px' }} />
                                </div>
                            </div>

                            <div className="col-lg-8 col-12 px-3 " >

                                <div className="row justify-content-center aligm-items-start d-flex flex-column g-2 ">

                                    <div
                                        className="my-0 d-flex justify-content-between align-items-center g-2"
                                    >
                                        <div className="col-10 m-0 d-flex align-items-end">
                                            <div className="reg_img" style={{ height: '100px', width: '100px', objectFit: 'cover' }}>
                                                <img id='prof_image' src={profile} className="img-fluid rounded-top "
                                                    style={{ borderRadius: "50%", height: "100%", width: '100%' }}
                                                    alt="new" />
                                            </div>
                                            <p className="fs-4 m-0 fw-bold ps-3">Register</p>
                                        </div>
                                        <div className="col-1 text-end align-self-start">
                                            <p className='m-0 py-3'><button className='btn btn-close' onClick={() => { closeSignup(); navigate('/') }}></button></p>
                                        </div>
                                    </div>


                                    <form onSubmit={(e) => handleSignupForm(e)} >

                                        <p className='m-1 fw-light text-muted'>Enter your information to register</p>
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="username" className="form-label m-0 ">Username <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                        </div>
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="school" className="form-label m-0 ">School </label>
                                            <input type="text" className="form-control" id="school" value={school} onChange={(e) => setSchool(e.target.value)} required />
                                        </div>
                                        <div className="mb-2 text-start labelFontSize" >
                                            <label for="email" className="form-label m-0 ">Email address <span style={{ color: 'red' }}>*</span></label>
                                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                            <div id="emailHelp" className="form-text">We use this email to reset your password.</div>
                                        </div>
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="exampleInputPassword1" className="form-label m-0 ">Password <span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={(e) => { setPassword(e.target.value); setError('') }} required />
                                        </div>
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="passwordConfirmation" className="form-label m-0 ">Password Confirmation <span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" className="form-control" id="passwordConfirmation" value={passwordConfirmation} onChange={(e) => { setPasswordConfirmation(e.target.value); setError('') }} required />
                                        </div>
                                        {error && <p className='text-danger fw-light'><small>{error}</small></p>}
                                        <button type="submit" className="btn btn-primary mb-2 rounded-0 btn-submit form-control flex-fill">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Signup