import React, { useState, useContext } from 'react'
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import './Reviews.css'
import { UrlContext } from '../../context/url'

function Reviews({ user, isLoggedIn, children }) {

  const {apiUrl} = useContext(UrlContext)

  const [review, setReview] = useState('')
  const [rating, setRating] = useState(null)
  const [hover, setHover] = useState(null)

  const MIN_LENGTH = 50;
  const MAX_LENGTH = 400;

  const handleReviewChange = (e) => {
    const content = e.target.value;
    if (content.length <= MAX_LENGTH) {
      setReview(content);
    }
  };

  const isReviewValid = review.length >= MIN_LENGTH && review.length <= MAX_LENGTH;

  function handleReview(e) {
    e.preventDefault()
    if (!user && isLoggedIn === false) {
      return
    } else if (review === '' || rating === null) {
      toast.error('Add a comment and Rating!')
      return
    }
    console.log('reviews');
    const token = localStorage.getItem("jwt");
    const promise = new Promise(async (resolve, reject) => {
      fetch(apiUrl + '/reviews', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accepts": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: user.id,
          review,
          rating
        })
      })
        .then(resp => resp.json())
        .then(d => {
          console.log(d)
          setRating(null)
          setReview('')
          resolve()
        })
        .catch(reject)
    })

    toast.promise(promise, {
      loading: "Sending ...",
      success: "Review sent",
      error: "An error occurred",
    })
  }

  return (
    <div className='bg-light'>
      <Toaster />
      <div className="testimonials testimonials-section d-flex justify-content-center align-items-center g-2" style={{ minHeight: '80vh' }}>
        <div className="container p-0">
          <div className="col-6 mx-auto text-center ">
            <h1 className="steps"
            ><span className='bg-light'> Reviews</span></h1>
            <motion.p className='fs-1 '
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >Review's from Students</motion.p>
          </div>
          <motion.div
            className="col-12"
            style={{ position: 'relative' }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.93 }}
            viewport={{ once: true }}
          >
            {children}

          </motion.div>

        </div>
      </div>
      {user && isLoggedIn &&
        <div className="col-lg-8 p-3 mx-auto pb-5" >

          <div className="card border-0" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px' }}>
            <div className="card-body">

              <form onSubmit={(e) => handleReview(e)}>
                <div className="mb-3">
                  <div className='d-flex gap-2 align-items-center mb-3'>
                    {user && <div style={{ height: '50px', width: '50px' }}><img className='img-fluid' style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }} src={user.img !== '' ? user.img : ''} alt="user" /></div>}
                    {/* <div style={{height:'50px',width:'50px'}}><img className='img-fluid' style={{height:'100%',width:'100%',objectFit:'contain',borderRadius:'50%'}} src={profile} alt="" /></div> */}
                    <div className='d-flex flex-column'>
                      <label>Rate the App</label>
                      <div className="d-flex">
                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1
                          return (
                            <label className="form-label">
                              <input type="radio" className='star_input'
                                name='rating'
                                value={currentRating}
                                onClick={() => setRating(currentRating)}
                              />
                              <FontAwesomeIcon
                                className='fs-4 star'
                                icon={faStar}
                                style={{ color: currentRating <= (hover || rating) ? '#FFB704' : '' }}
                                onMouseEnter={() => setHover(currentRating)}
                                onMouseLeave={() => setHover(null)}
                              />
                            </label>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <textarea className="form-control rounded-0" name="review"
                    minLength={MIN_LENGTH}
                    maxLength={MAX_LENGTH}
                    placeholder='Your review must be atleast 50 characters' value={review} onChange={(e) => handleReviewChange(e)} rows="3"></textarea>

                </div>
                <div className="mb-3 d-flex">
                  <button type='submit' disabled={!isReviewValid} className="btn flex-fill rounded-0" style={{ backgroundColor: '#002270', color: '#ffffff' }}>Submit</button>
                </div>
              </form>
            </div>
          </div>


        </div>
      }
    </div>
  )
}

export default Reviews