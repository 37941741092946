import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css';
import profile from '../../Assets/img/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


function ImageSlider({allReviews}){


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='col-lg-12' style={{height:'50vh  ',width:'auto',position:'relative'}}>
      {/* <div className="review_bg rounded " style={{position:'absolute',left:'10vh',top:'-5%', height:'40vh',width:'80%',zIndex:0, backgroundColor:'#FDE79F'}}></div> */}
      <Slider {...settings}> 
      {allReviews.length > 0 && allReviews.filter(review=>review.approved === true).map((d)=>{
        return(
        <div key={d.id} className='card mx-0 my-4 ' style={{minHeight:'35vh',minWidth:'400vw',boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px;'}}>
          <div className="card-body d-flex justify-content-center flex-column" style={{minHeight:'inherit'}}>
          <h4 className="card-title">
            {[...Array(d.rating)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} style={{color:'#FFB704'}} />
            ))}
          </h4>
              <p className="card-text" style={{color:'#002270'}}>{d.review} </p>
              <div className="d-flex justify-content-start m-0">
              <div className="img-div">
                  <img src={d.user.img ?  d.user.img : profile} alt="info" className="img-fluid " style={{ borderRadius:'50%',width:'50px',height:'50px'}} />
              </div>
              <div>
                <p className="m-0 text-muted">{d.user.username}</p>
                <p className="m-0 text-muted">{d.user.school}</p>
              </div>
              </div>
          </div>
          
        </div>
        )
      })
      }
      </Slider>
      
    </div>
  )
}

export default ImageSlider