import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons';
import students from '../../Assets/img/students.jpg'  
import {topicsArr} from '../../Assets/arrayData/topicsArr'

function Resources() {
  return (
    <div className='wrapper'>
      <div className="section courses-section" style={{backgroundColor: '#f8ecef'}}>
                <div className="container py-5">
                    <div
                        className="row justify-content-center align-items-center g-2 "
                    >
                        <div className="col-12 ">
                            <div className="mx-auto col-6 text-center">
                            <h4 className='col-7 courses  mx-auto'><span>Study Resources</span></h4>
                            <h2>Most Popular Disciplines</h2>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-6 col-11 p-2" >
                            <div className="card border-0  text-start p-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'}}>
                                <img className="card-img-top" src={students} alt="Title" />
                                <div className="card-body">
                                <p className="text-small m-0">15 lessons &nbsp; 7 hours 30 minutes</p>
                                    <h4 className="card-title">Humanity</h4>
                                    <p className="card-text"> 3 Reviews</p>
                                    <div
                                        className="row justify-content-between align-items-center g-2"
                                    >
                                        <div className="col-6"><button className="btn border-black text-danger">Enroll Now <ArrowRight /></button></div>
                                        <div className="col-2">FREE</div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-6 col-11 p-2" >
                            <div className="card border-0  text-start p-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'}}>
                                <img className="card-img-top" src={students} alt="Title" />
                                <div className="card-body">
                                    <p className="text-small m-0">15 lessons &nbsp; 7 hours 30 minutes</p>
                                    <h4 className="card-title">Business & Finance</h4>
                                    <p className="card-text"> 3 Reviews</p>
                                    <div
                                        className="row justify-content-between align-items-center g-2"
                                    >
                                        <div className="col-6"><button className="btn border-black text-danger">Enroll Now <ArrowRight /></button></div>
                                        <div className="col-2">FREE</div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-6 col-11 p-2" >
                            <div className="card border-0  text-start p-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'}}>
                                <img className="card-img-top" src={students} alt="Title" />
                                <div className="card-body">
                                    <p className="text-small m-0">15 lessons &nbsp; 7 hours 30 minutes</p>
                                    <h4 className="card-title">Computer Science</h4>
                                    <p className="card-text"> 3 Reviews</p>
                                    <div
                                        className="row justify-content-between align-items-center g-2"
                                    >
                                        <div className="col-6"><button className="btn border-black text-danger">Enroll Now <ArrowRight /></button></div>
                                        <div className="col-2">FREE</div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    {topicsArr && topicsArr.map((topic)=>{
                        return(
                            <div className="col-lg-6 col-md-6 col-11 py-3 px-2" >
                            <div className="card border-0  text-start px-3 py-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'}}>
                                <div className="d-flex gap-2">
                                    <div className="card-img-div " style={{height:'150px',width:'200px'}}>
                                    <img className="card-img-top img-fluid rounded-0" src={students} alt="Title"  style={{width:'100%',height: '100%',objectFit:'contain'}}/>
                                </div>
                                <div className="card-details">
                                    <h4 className="card-title">{topic}</h4>
                                    <p className="card-text"> 3 Reviews</p>
                                    <div
                                        className="row justify-content-between align-items-center g-2"
                                    >
                                        <div className="col-">FREE</div>
                                    </div>
                                    
                                </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        )
                    })}
                        
                        
                        
                    </div>
                    
                </div>

            </div>
  </div>
  )
}

export default Resources