import React, { useState, useContext } from 'react';
import toast,{Toaster} from 'react-hot-toast';
import './ResetPassword.css'
import {UrlContext} from '../../context/url'


function ResetPassword({closeReset}) {

    const [email, setEmail] = useState('');
    const  {apiUrl} = useContext(UrlContext)
    

    const handleResetPassword = async (e) => {
        e.preventDefault()
        try {
          const response = await fetch( apiUrl + '/password_reset',{
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accepts: 'application/json'
            },
            body:JSON.stringify({
                email,
            })
          })

          const data = await response.json();
          if(response.ok){
            console.log('data',data); 
            toast.success(data.message);
          }else {
            toast.error(data.message);
              console.log('Failed resetting password')
              
          }
          // Handle success, maybe show a success message
        } catch (error) {
          console.error('Error resetting password:', error);
          // Handle error, show an error message to the user
        }
      };

    
  return (
      <div className='reset-overlay'>
         <div className="container">
            <div className="row justify-content-center align-items-center g-2">
                <div className="col-lg-4 col-10 rounded bg-white ">
                    <div className="row justify-content-center align-items-center g-2   ">
                        <div className=" col-12 px-3  " >
                         
                            <div className="row justify-content-center aligm-items-start d-flex flex-column g-2 py-3 ">
                                
                                <div className="row  d-flex justify-content-between align-items-start g-2  m-0 px-2" >
                                    <div className="col-10 m-0">
                                        <p className="fs-5 m-0 fw-bold ">Forgot your password?</p>
                                        <p className='m-1'>Enter email to Reset</p>
                                    </div>
                                    <div className="col-1 text-end align-self-start ">
                                        <button className='btn btn-close ' onClick={()=>closeReset()}></button>
                                    </div>
                                </div>
                                
                        <form onSubmit={(e)=>handleResetPassword(e)} >
                            <div className="mb-2 text-start">
                                <label for="email" className="form-label  ">Email </label>
                                <input type="text" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                            </div>
                            <button type="submit" className="btn btn-primary mb-2 rounded-0 btn-submit form-control flex-fill">Submit</button>
                        </form>
                        <Toaster/>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
        
    </div>

  )
}

export default ResetPassword

