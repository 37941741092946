import React, { useState, useContext } from 'react'
import welcomeback from '../../Assets/img/welcomeback.png'
import fb from '../../Assets/icons/fb.png'
import ig from '../../Assets/icons/ig.png'
import x from '../../Assets/icons/x.png'
import google from '../../Assets/icons/google.png'
import './Signin.css'
import toast, { Toaster } from 'react-hot-toast';
import {UrlContext} from '../../context/url'
import { useNavigate } from 'react-router'

function Signin({ closeSignin, setLogged, openReset, setUser, setLoading }) {

    const  {apiUrl} = useContext(UrlContext)
    const navigate = useNavigate()

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');




    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)
        console.log('start',apiUrl);

        const promise = new Promise((resolve, reject) => {
            fetch(apiUrl + '/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    username,
                    password
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    localStorage.setItem("jwt", data.jwt);
                    localStorage.setItem("kqt_user_id", data.user.id);
                    setLogged(true)
                    setUser(data.user)
                    closeSignin()
                    resolve()
                    setLoading(false)
                    let home
          if (data.user.role === 'admin') {
            home = '/admin_home'
          } else if (data.user.role === 'writer') {
            home = '/writer_home'
          }else if (data.user.role === 'member') {
             home = '/member_home'
          }
          navigate(home)
                })
                .catch((error) => {
                    reject()
                    setLoading(false)
                })
        })
        toast.promise(promise, {
            loading: "Fetching user ...",
            success: "Login successful",
            error: "failed.incorrect username or password",
        })
    }

    return (
        <div className='signin-section signin-overlay'>
            <div className="container">
                <Toaster />
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-lg-7 col-10 rounded bg-white ">
                        <div className="row justify-content-center align-items-center g-2   ">
                            <div className="col-lg-4 d-lg-flex d-none p-0" style={{ height: '80vh' }}>
                                <div className="signup-img-div" >
                                    <img className='img-fluid' src={welcomeback} alt="info" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px 0 0 5px' }} />
                                </div>
                            </div>

                            <div className="col-lg-8 col-12 px-3  " >

                                <div className="row justify-content-center aligm-items-start d-flex flex-column g-2 py-3 ">

                                    <div
                                        className="row  d-flex justify-content-between align-items-start g-2  m-0 px-2"
                                    >
                                        <div className="col-10 m-0">
                                            <p className="fs-4 m-0 fw-bold ">Login</p>
                                            <p className="fs-5 m-0 fw-bold ">Welcome back</p>
                                            <p className='m-1'>Enter your information to Login</p>
                                        </div>
                                        <div className="col-1 text-end align-self-start ">
                                            <button className='btn btn-close ' onClick={() => closeSignin()}></button>
                                        </div>
                                    </div>


                                    <form onSubmit={(e) => handleSubmit(e)} >
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="username" className="form-label m-0 ">Username <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                        </div>
                                        <div className="mb-2 text-start labelFontSize">
                                            <label for="exampleInputPassword1" className="form-label m-0 ">Password <span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        </div>
                                        <div className="row py-3">
                                            <div className=" col-md-8 mx-auto d-flex justify-content-between">
                                                <img className='img-logo' src={fb} alt="info" />
                                                <img className='img-logo' src={x} alt="info" />
                                                <img className='img-logo' src={ig} alt="info" />
                                                <img className='img-logo' src={google} alt="info" />
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                        {error && <p className='text-danger'>{error}</p>}
                                        <button type="submit" className="btn btn-primary mb-2 rounded-0 btn-submit form-control flex-fill">Submit</button>
                                        <div className="row pt-3 justify-content-end"><p className="text-danger text-end col-9" onClick={() => openReset()}>forgot password?</p></div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Signin