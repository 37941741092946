import React, { useContext, useEffect, useState } from "react";
import DropIn from "braintree-web-drop-in-react";
import { UrlContext } from "../../context/url";

const Payment = ({ onPaymentMethodNonce, clientToken, loading, amount, question_id}) => {
  const { apiUrl } = useContext(UrlContext);

  const [instance, setInstance] = useState(null);
  const [error, setError] = useState(null);

  const handlePaymentMethodNonce = (nonce) => {
    onPaymentMethodNonce(nonce);
  };

  const handlePayment = async () => {
    const token = localStorage.getItem("jwt");
    try {
      const { nonce } = await instance.requestPaymentMethod();
      const response = await fetch(apiUrl + "/payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ nonce, amount, question_id}),
      });
      const result = await response.json();
      if (result.success) {
        console.log("Payment successful", result);
        handlePaymentMethodNonce(nonce);
      } else {
        console.log("Payment failed: " + result.message);
      }
    } catch (error) {
      console.error(error);
      alert("Payment fail");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {clientToken ? (
        <div>
          <DropIn
            options={{
              authorization: clientToken,
              paymentOptionPriority: ["card", "venmo", "googlePay", "applePay"],
              venmo: {
                allowNewBrowserTab: false,
              },
              googlePay: {
                googlePayVersion: 2,
                merchantId: "your-merchant-id",
                transactionInfo: {
                  totalPrice: amount,
                  currencyCode: "USD",
                },
              },
              applePay: {
                displayName: "Your Store Name",
                paymentRequest: {
                  total: {
                    label: "Your Store Name",
                    amount: amount,
                  },
                  currencyCode: "USD",
                },
              },
            }}
            onInstance={(instance) => setInstance(instance)}
            onError={(error) => console.error("DropIn Error:", error)}
          />
          <div class="row justify-content-end align-items-center g-2">
            <div className="col-2 col-md-3 d-flex">
              <button
                className="btn btn-primary flex-fill"
                onClick={handlePayment}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>No client token available</div>
      )}
    </div>
  );
};

export default Payment;
